import { endOfDay, endOfMonth, format, startOfDay, startOfMonth } from 'date-fns'
import {
  GetPOActivityTableDataSearchParams,
  POActivityReceiveProductTable,
  getPOActivityReceiveProductTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableDataV2 } from '../useTablaData'

const _buildQueryParams = (_s: POActivityReceiveProductTable.SearchParams): GetPOActivityTableDataSearchParams => {
  return {
    startDateTime: format(startOfDay(_s.startDateTime), 'yyyy-MM-dd HH:mm:ss'),
    endDateTime: format(endOfDay(_s.endDateTime), 'yyyy-MM-dd HH:mm:ss'),
    purchaseOrderCode: _s.purchaseOrderCode,
  }
}

export const usePurchaseOrderActivityReceiveProduct = () =>
  useTableDataV2<
    POActivityReceiveProductTable.Data,
    {},
    POActivityReceiveProductTable.SearchParams,
    GetPOActivityTableDataSearchParams
  >(
    0,
    10,
    { purchaseOrderCode: undefined, startDateTime: startOfMonth(new Date()), endDateTime: endOfMonth(new Date()) },
    getPOActivityReceiveProductTableDataAPI,
    _buildQueryParams,
  )
