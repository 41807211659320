import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { Box } from '@material-ui/core'
import {
  Avatar,
  FlexBox,
  GeneralTextField,
  Link,
  Table,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { usePurchaseOrderActivityReceiveProduct } from '../../../hooks'
import { Loading } from '../../../shared-components'
import { POActivityReceiveProductTable } from '../../../api/smartbestbuys-api'
import { dateFormat } from '../../../functions'
import { Photo } from '@material-ui/icons'
import moment from 'moment'

const WarehouseReceiveProductActivityTableDataPage: React.FC = () => {
  const tableData = usePurchaseOrderActivityReceiveProduct()
  if (!tableData) return <Loading />

  const {
    data,
    paginationInfo,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
    isLoading,
    pendingSearch,
  } = tableData

  console.log('pendingSearch : ', pendingSearch)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>รายการใบสั่งซื้อที่มีการรับสินค้า</Topic>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="startDateTime"
                  label="วันที่รับสินค้า (ตั้งแต่)"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={
                    pendingSearch?.startDateTime ? moment(pendingSearch.startDateTime).format('YYYY-MM-DD') : undefined
                  }
                  onChange={(e) => handleSearchParamChange('startDateTime', new Date(e.target.value))}
                ></GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="date"
                  name="startDate"
                  label="วันที่รับสินค้า (สิ้นสุด)"
                  defaultValue={
                    pendingSearch?.endDateTime ? moment(pendingSearch.endDateTime).format('YYYY-MM-DD') : undefined
                  }
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleSearchParamChange('endDateTime', new Date(e.target.value))}
                ></GeneralTextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GeneralTextField
                  type="text"
                  name="purchaseOrderCode"
                  onChange={(e) => handleSearchParamChange('purchaseOrderCode', e.target.value)}
                  label="รหัสใบสั่งซื้อ"
                  value={pendingSearch?.purchaseOrderCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Grid item xs={12} sm={10}></Grid>
              <Grid item xs={12} sm={2}>
                <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </form>
          <Table<POActivityReceiveProductTable.Data>
            color="primary.dark"
            columns={[
              {
                name: 'วันที่รับสินค้า',
                render: ({ recCreatedAt }) => dateFormat(new Date(recCreatedAt)),
                fixedWidth: 150,
              },
              {
                name: 'ผู้รับสินค้า',
                render: ({ recCreatedBy }) => recCreatedBy,
                fixedWidth: 150,
              },
              {
                name: 'ข้อมูลใบสั่งซื้อ',
                render: ({ purchaseOrder, saleOrder, vendor, vendorContact }) => {
                  return (
                    <div>
                      <TypographyWithLabel label="รหัสใบซื้อ:">{purchaseOrder.code}</TypographyWithLabel>
                      <TypographyWithLabel label="รหัสใบสั่งขายอ้างอิง:">
                        {saleOrder?.id ? saleOrder.code : '-'}
                      </TypographyWithLabel>
                      <TypographyWithLabel label="ผู้ขาย:">{vendor.name}</TypographyWithLabel>
                      <TypographyWithLabel label="ผู้ติดต่อ:">
                        {vendorContact?.id ? vendorContact.name : '-'}
                      </TypographyWithLabel>
                    </div>
                  )
                },
                fixedWidth: 400,
              },
              {
                name: 'รายละเอียดสินค้า',
                render: ({ state }) => {
                  return (
                    <div className="w-full h-full border-l-2 border-r-2 px-3">
                      {state?.results.map((result, index) => (
                        <div className="mb-3">
                          <FlexBox justifyContent="space-between">
                            <Box display="flex" alignItems="flex-start" className="w-9/12">
                              <Avatar variant="rounded" src={result.imageUrl} objectFit="contain">
                                <Photo />
                              </Avatar>

                              <FlexBox ml={1} flexDirection="column">
                                <Link color="primary" to={`/products/${result.id}/profile`}>
                                  {result.code}
                                </Link>

                                <Typography variant="body2">{result.name}</Typography>
                              </FlexBox>
                            </Box>

                            <Typography variant="body2" className="w-3/12 text-right">
                              จำนวนที่รับ :{result.receivedAmount} {result.unit}
                            </Typography>
                          </FlexBox>
                          {index + 1 !== state.results.length && <hr className="my-3" />}
                        </div>
                      ))}
                    </div>
                  )
                },
              },
              {
                name: 'หมายเหตุ',
                render: ({ remark }) => remark ?? '',
                fixedWidth: 200,
              },
            ]}
            data={data}
            size="small"
            sortable
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            onRowPerPageChange={handleRowPerPageChange}
            cleanSpace
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(WarehouseReceiveProductActivityTableDataPage, true)
