/* eslint-disable react-hooks/exhaustive-deps */

import {
  GetProductTableDataSearchParams,
  IProductTableData_Data,
  IProductTableData_SearchParams,
  getProductPaginationAPI,
} from '../../api/smartbestbuys-api'

import { useTableData } from '../useTablaData'
import { ProductMode, ProductType } from '../../enums'

const _buildQueryParams = (_s: IProductTableData_SearchParams) => {
  return {
    name: _s.name,
    code: _s.code,
    mode: _s.mode,
    gradeList: _s?.gradeList?.join(','),
    typeIds: !!_s.typeIds && _s.typeIds.length > 0 ? _s.typeIds.join(',') : undefined,
    isPendingVerified: _s.isPendingVerified,
    isRequestChangedData: _s.isRequestChangedData,
  }
}

export const useProductTableData = (_rowPerPage?: number) =>
  useTableData<IProductTableData_Data, {}, IProductTableData_SearchParams, GetProductTableDataSearchParams>(
    0,
    _rowPerPage ? _rowPerPage : 10,
    {
      mode: ProductMode.Available,
      gradeList: [],
      typeIds: [ProductType.Product],
    },
    getProductPaginationAPI,
    _buildQueryParams,
  )
