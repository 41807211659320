import { useEffect, useState } from 'react'
import {
  getPurchaseOrderPendingReceiveProductAPI,
  IGetPurchaseOrderPendingReceiveProduct,
} from '../../api/smartbestbuys-api'

export const usePurchaseOrderPendingReceiveProduct = (
  productTypeId: number | undefined,
  reload?: boolean,
): IGetPurchaseOrderPendingReceiveProduct[] => {
  const [data, setData] = useState<IGetPurchaseOrderPendingReceiveProduct[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getPurchaseOrderPendingReceiveProductAPI({
        productTypeId: productTypeId ? productTypeId.toString() : undefined,
      })
      if (response) {
        setData(response?.data ?? [])
      }
    })()
  }, [productTypeId, reload])

  return data
}
