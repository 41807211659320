export enum TabEnums {
  Contract = 'contract',
  Address = 'address',
  PurchaseLog = 'purchaseLog',
  CustomerDocument = 'customerDocument',
  BackAccount = 'backAccount',
  Transportation = 'transportation',
  Debt = 'dept',
  Refund = 'refund',
}
