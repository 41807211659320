import { PurchaseOrderStatus } from '../enums'

export const whitelistedStatusToPurchaseOrderReceiveProduct = [
  PurchaseOrderStatus.PaymentPending,
  PurchaseOrderStatus.DepositPending,
  PurchaseOrderStatus.AppointmentPending,
  PurchaseOrderStatus.ProductPending,
  PurchaseOrderStatus.VendorShippingToCustomer,
  PurchaseOrderStatus.PartialReceived,
]
