import { useEffect, useState } from 'react'
import {
  getPurchaseOrderPendingReceiveProductByIdAPI,
  IGetPOPendingReceiveProductById,
} from '../../api/smartbestbuys-api'

export const usePurchaseOrderPendingReceiveProductById = (
  id: number,
  productTypeId: number | undefined,
): IGetPOPendingReceiveProductById | undefined => {
  const [data, setData] = useState<IGetPOPendingReceiveProductById | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const response = await getPurchaseOrderPendingReceiveProductByIdAPI(id, {
        productTypeId: productTypeId ? productTypeId.toString() : undefined,
      })
      if (response) {
        setData(response?.data)
      }
    })()
  }, [productTypeId, id])

  return data
}
