import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { useContext, useState } from 'react'
import { RootContext } from '../../..'
import { IGetPurchaseOrderPendingReceiveProduct } from '../../../api/smartbestbuys-api'
import { FilteredTable, FlexBox, Link, Topic, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { usePurchaseOrderPendingReceiveProduct } from '../../../hooks'
import PendingReceiveProductDialog from './PendingReceiveProductDialog'
import { useHistory } from 'react-router-dom'

const WarehousePendingReceiveProductListPage = () => {
  const history = useHistory()
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.WAREHOUSE_PENDING_VENDOR_SHIPPING)

  // state

  const [selectPurchaseOrderId, setSelectPurchaseOrderId] = useState<number | undefined>(undefined)
  const [reload, setReload] = useState(false)

  // hook
  const purchaseOrders = usePurchaseOrderPendingReceiveProduct(undefined, reload)

  return (
    <Box p={2}>
      {selectPurchaseOrderId && (
        <PendingReceiveProductDialog
          open={!!selectPurchaseOrderId}
          purchaseOrderId={selectPurchaseOrderId}
          onComplete={() => {
            setReload((prev) => !prev)
          }}
          onClose={() => {
            setSelectPurchaseOrderId(undefined)
          }}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Topic>ติดตามสินค้าและบริการจากผู้ขาย</Topic>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/warehouse/receive-product-activity`)
              }}
            >
              ดูประวัติการรับสินค้า
            </Button>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FilteredTable<IGetPurchaseOrderPendingReceiveProduct>
              color="primary.dark"
              columns={[
                {
                  name: 'รับสินค้า',
                  dataIndex: 'code',
                  render: ({ id }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      classes={{ label: 'px-2 leading-none' }}
                      onClick={() => setSelectPurchaseOrderId(id)}
                    >
                      รับสินค้าจากผู้ขาย
                    </Button>
                  ),
                },
                {
                  name: 'ใบสั่งซื้ออ้างอิง',
                  dataIndex: 'code',
                  render: ({ id, code }) => (
                    <Link color="primary" to={`/purchase-orders/${id}/profile`} target="_blank">
                      <Box color="primary.dark">
                        <Typography variant="body2">{code}</Typography>
                      </Box>
                    </Link>
                  ),
                },
                {
                  name: 'วันที่นัดส่งสินค้า',
                  render: ({ vendorShippingDate }) => (!!vendorShippingDate ? dateFormat(vendorShippingDate) : '-'),
                },
                {
                  name: 'ชื่อผู้ขาย',
                  render: ({ vendor }) => vendor.name,
                },
                {
                  name: 'วันที่สร้าง',
                  render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                },
              ]}
              sortable
              data={purchaseOrders}
              disableDateRangeFilter
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withLayout(WarehousePendingReceiveProductListPage)
