import { Box, Button, Grid, MenuItem, Popover } from '@material-ui/core'
import { ArrowForward, MoreHoriz, NoteAdd, PictureAsPdf } from '@material-ui/icons'
import React, { createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RootContext } from '../../..'
import { PurchaseOrderProfile } from '../../../api/smartbestbuys-api/purchase-order/types'
import {
  BreadcumbsList,
  CustomButton,
  CustomSpeedDial,
  FlexBox,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { PageName, PaymentRequisitionStatus, PurchaseOrderStatus, PurchaseRequisitionStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { usePurchaseOrderProfile } from '../../../hooks/purchase'
import {
  ConditionTooltip,
  Loading,
  MultipleConditionTooltip,
  PurchaseOrderPaymentStatusChip,
  PurchaseOrderStatusChip,
  PurchaseRequisitionCancelSignal,
  SendToManagerCancelSignal,
} from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import PurchaseOrderProfileAttachmentBox from './PurchaseOrderProfileAttachmentBox'
import PurchaseOrderProfileCallLeaderDialog from './PurchaseOrderProfileCallLeaderDialog'
import PurchaseOrderProfileCancelDialog from './PurchaseOrderProfileCancelDialog'
import PurchaseOrderProfileDocumentBox from './PurchaseOrderProfileDocumentBox'
import PurchaseOrderProfileMarkAsUnCancelableDialog from './PurchaseOrderProfileMarkAsUnCancelableDialog'
import PurchaseOrderProfilePaymentRequisitionBox from './PurchaseOrderProfilePaymentRequisitionBox'
import PurchaseOrderProfileProductsTable from './PurchaseOrderProfileProductsTable'
import PurchaseOrderProfileUserActivitiesTable from './PurchaseOrderProfileUserActivitiesTable'
import PurchaseOrderProfileVendorInfo from './PurchaseOrderProfileVendorInfo'
import PurchaseOrderTakeNoteDialog from './PurchaseOrderTakeNoteDialog'
import PurchaseOrderVerificationBox from './PurchaseOrderVerificationBox'
import PurchaseRequisitionRefBox from './PurchaseRequisitionRefBox'
import VendorAppointmentBox from './VendorAppointmentBox'
import VendorProfileCard, { mapPurchaseOrderProfileToValue } from './VendorProfileCard'

interface IPurchaseOrderProfilePageContext {
  purchaseOrderProfile: PurchaseOrderProfile
  forceReload: () => void
}

export const PurchaseOrderProfilePageContext = createContext<IPurchaseOrderProfilePageContext>({
  purchaseOrderProfile: {} as PurchaseOrderProfile,
  forceReload: () => {},
})

interface Props {
  id: number
}

const PurchaseOrderProfilePage: React.FC<Props> = (props) => {
  // set up props & state & context
  const { id } = props
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)

  // useState
  const [reload, setReload] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  setCurrentPage(PageName.PURCHASE_ORDER)

  const history = useHistory()

  const readPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Read)

  if (!readPermission.hasPermission) {
    history.push('/')
  }

  // call hooks
  const profile = usePurchaseOrderProfile(id, reload)
  const forceReload = () => {
    setReload((prev) => !prev)
  }

  if (!profile) return <Loading />

  // calculation
  const { status } = profile
  const breadcumbsItems = [{ url: '/purchase-orders', name: 'รายการใบสั่งซื้อ' }, { name: profile?.code ?? '...' }]
  const ableToSeeVeificationBox = status.id === PurchaseOrderStatus.ReviewPending

  // data
  const pathPDF = profile.vendorInfo.country !== 'ไทย' ? `/purchase-orders/${id}/pdf/en` : `/purchase-orders/${id}/pdf`

  const actions = [
    {
      icon: <PictureAsPdf />,
      name: 'ดูเป็น PDF',
      onClick: () => window.open(pathPDF),
    },
    {
      icon: <NoteAdd />,
      name: 'บันทึกใบสั่งซื้อ',
      onClick: () => setOpenDialog(true),
    },
  ]

  // render
  return (
    <PurchaseOrderProfilePageContext.Provider
      value={{
        purchaseOrderProfile: profile,
        forceReload,
      }}
    >
      <PurchaseOrderTakeNoteDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSuccess={() => {
          forceReload()
          triggerSnackbar('บันทึกเรียบร้อย')
          setOpenDialog(false)
        }}
        onFail={() => {
          triggerSnackbar('ไม่สามารถบันทึกได้')
          setOpenDialog(false)
        }}
      />
      <Box p={3} clone>
        <Grid container spacing={4}>
          <CustomSpeedDial actions={actions} />
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <FlexBox alignItems="center">
              <Topic>ใบสั่งซื้อเลขที่ {profile.code}</Topic>
              <span className="mr-4" />
              <PurchaseOrderProfileSignalGroup />
            </FlexBox>
            <PurchaseOrderProfileMenues />
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <PurchaseOrderProfileStatusGroup />
            <PurchaseOrderProfileActionGroup />
          </Grid>

          {ableToSeeVeificationBox && (
            <Grid item xs={12}>
              <PurchaseOrderVerificationBox />
            </Grid>
          )}
          <Grid item xs={12}>
            <VendorAppointmentBox />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderProfileDocumentBox />
          </Grid>
          <Grid item xs={12}>
            <PurchaseRequisitionRefBox />
          </Grid>
          <Grid item xs={12}>
            <VendorProfileCard
              value={mapPurchaseOrderProfileToValue(profile)}
              options={{
                actionButton: {
                  text: 'ไปที่หน้ารายละเอียดผู้ขาย',
                  onClick: () => {
                    window.open(`/vendors/${profile.vendorInfo.id}/profile`)
                  },
                  suffixIcon: <ArrowForward />,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderProfileProductsTable hideSalePrice />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderProfileVendorInfo />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderProfileAttachmentBox />
          </Grid>
          {/* <Grid item xs={12}>
            <PurchaseOrderProfileTaskTable />
          </Grid> */}
          <Grid item xs={12}>
            <PurchaseOrderProfilePaymentRequisitionBox />
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderProfileUserActivitiesTable />
          </Grid>
        </Grid>
      </Box>
    </PurchaseOrderProfilePageContext.Provider>
  )
}

export default withLayout(PurchaseOrderProfilePage)

const PurchaseOrderProfileStatusGroup: React.FC = () => {
  // set up props & state
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { status, paymentStatus, addtionalData } = purchaseOrderProfile

  // calculation
  const textAlertFailed = status.id === PurchaseOrderStatus.Rejected ? 'สาเหตุที่ไม่อนุมัติ' : 'สาเหตุที่ยกเลิก'

  return (
    <FlexBox alignItems="center">
      <PurchaseOrderStatusChip icon={true} status={status.id} label={status.name} />
      <span className="mr-4"></span>
      <PurchaseOrderPaymentStatusChip icon={true} status={paymentStatus.id} label={paymentStatus.name} />
      <span className="mr-4"></span>
      {!!addtionalData?.activityRemark && (
        <TypographyWithLabel label={textAlertFailed} color="error">
          {addtionalData.activityRemark}
        </TypographyWithLabel>
      )}
    </FlexBox>
  )
}

const PurchaseOrderProfileActionGroup: React.FC = () => {
  // set up props & state
  const { triggerSnackbar } = useContext(RootContext)
  const { purchaseOrderProfile, forceReload } = useContext(PurchaseOrderProfilePageContext)

  const [callLeaderOpen, setCallLeaderOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [unCancellableOpen, setUnCancellableOpen] = useState(false)

  // prepare data
  const { id, code, status, cancellable, purchaseRequisitionInfo } = purchaseOrderProfile

  // calculation
  const endedStatusesList = [PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Received, PurchaseOrderStatus.Canceled]
  const isEnded = endedStatusesList.includes(status.id)
  const isPRCanceled = purchaseRequisitionInfo.status.id === PurchaseRequisitionStatus.Canceled
  const isLeaderPending = status.id === PurchaseOrderStatus.LeaderPending

  const showedCallLeaderButton = !isEnded && !isLeaderPending && isPRCanceled && !!cancellable
  const showedMarkAsUnCancelableButton = isLeaderPending && !!cancellable
  const showedCancelButton = !isEnded && (isPRCanceled || isLeaderPending) && !!cancellable

  // permission
  const callLeaderPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_CallLeader)
  const markUnCancelPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_MarkUnCancelable)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Cancel)

  return (
    <FlexBox>
      <PurchaseOrderProfileCallLeaderDialog
        purchaseOrderId={id}
        open={callLeaderOpen}
        onClose={() => setCallLeaderOpen(false)}
        onComplete={() => {
          triggerSnackbar(`แจ้งหัวหน้าเรื่องใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCallLeaderOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`แจ้งหัวหน้าเรื่องใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseOrderProfileCancelDialog
        purchaseOrderId={id}
        purchaseOrderCode={code}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCancelOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseOrderProfileMarkAsUnCancelableDialog
        open={unCancellableOpen}
        onClose={() => setUnCancellableOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setUnCancellableOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      {showedCallLeaderButton && (
        <ConditionTooltip showTooltip={!callLeaderPermission.hasPermission} title={callLeaderPermission.alertMessage}>
          <span className="mr-2">
            <CustomButton
              disabled={!callLeaderPermission.hasPermission}
              variant="contained"
              classes={{ label: 'px-4 py-1 leading-none' }}
              color="warning"
              onClick={() => setCallLeaderOpen(true)}
            >
              แจ้งหัวหน้า
            </CustomButton>
          </span>
        </ConditionTooltip>
      )}
      {showedMarkAsUnCancelableButton && (
        <ConditionTooltip
          showTooltip={!markUnCancelPermission.hasPermission}
          title={markUnCancelPermission.alertMessage}
        >
          <span className="mr-2">
            <CustomButton
              disabled={!markUnCancelPermission.hasPermission}
              variant="contained"
              classes={{ label: 'px-4 py-1 leading-none' }}
              color="warning"
              onClick={() => setUnCancellableOpen(true)}
            >
              บันทึกว่าผู้ขายไม่ให้ยกเลิก
            </CustomButton>
          </span>
        </ConditionTooltip>
      )}
      {showedCancelButton && (
        <ConditionTooltip showTooltip={!cancelPermission.hasPermission} title={cancelPermission.alertMessage}>
          <span className="mr-2">
            <CustomButton
              disabled={!cancelPermission.hasPermission}
              variant="contained"
              classes={{ label: 'px-4 py-1 leading-none' }}
              color="error"
              onClick={() => setCancelOpen(true)}
            >
              ยกเลิกใบสั่งซื้อ
            </CustomButton>
          </span>
        </ConditionTooltip>
      )}
    </FlexBox>
  )
}

const PurchaseOrderProfileSignalGroup: React.FC = () => {
  // set up props & state
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { status, purchaseRequisitionInfo } = purchaseOrderProfile

  // calculation
  const isPRCanceled = purchaseRequisitionInfo.status.id === PurchaseRequisitionStatus.Canceled
  const isLeaderPending = status.id === PurchaseOrderStatus.LeaderPending

  return (
    <FlexBox>
      {isLeaderPending && (
        <span className="mr-4">
          <SendToManagerCancelSignal chip={true} />
        </span>
      )}
      {isPRCanceled && (
        <span className="mr-4">
          <PurchaseRequisitionCancelSignal chip={true} />
        </span>
      )}
    </FlexBox>
  )
}

const PurchaseOrderProfileMenues: React.FC = () => {
  // set up props & state & context
  const { triggerSnackbar } = useContext(RootContext)
  const { purchaseOrderProfile, forceReload } = useContext(PurchaseOrderProfilePageContext)
  const history = useHistory()
  const [callLeaderOpen, setCallLeaderOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)

  const { id, code, status, purchaseRequisitionInfo, products, cancellable } = purchaseOrderProfile

  // data
  const endedStatusesList = [PurchaseOrderStatus.Rejected, PurchaseOrderStatus.Received, PurchaseOrderStatus.Canceled]
  const failedPaymentRequisitionStatus = [PaymentRequisitionStatus.Rejected, PaymentRequisitionStatus.Canceled]

  // calculation
  const receivedAmount = products.reduce((sum, product) => {
    return sum + product.receivedAmount
  }, 0)
  const isEnded = endedStatusesList.includes(status.id)
  const isLeaderPending = status.id === PurchaseOrderStatus.LeaderPending
  const isPRCanceled = purchaseRequisitionInfo.status.id === PurchaseRequisitionStatus.Canceled
  const validPayments = purchaseOrderProfile.payments?.filter(
    (payment) => !failedPaymentRequisitionStatus.includes(payment.status.id),
  )

  const hasValidPayment = !!validPayments && validPayments.length > 0

  // display logic
  const showedMenu = !isEnded && !isLeaderPending
  const showedEditMenuItem = showedMenu && receivedAmount === 0 && !hasValidPayment
  const showedCancelMenuItem = showedMenu && !isPRCanceled
  const showedCallLeaderMenuItem = showedMenu && !isPRCanceled && !!cancellable

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const menuOpened = Boolean(anchorEl)

  if (!showedMenu) return <></>

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Write)
  const callLeaderPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_CallLeader)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Cancel)

  // tooltip conditions
  const cancelConditions = [
    { showTooltip: !cancelPermission.hasPermission, message: cancelPermission.alertMessage },
    { showTooltip: !cancellable, message: 'ผู้ขายไม่ให้ยกเลิก' },
  ]

  return (
    <FlexBox>
      <PurchaseOrderProfileCallLeaderDialog
        purchaseOrderId={id}
        open={callLeaderOpen}
        onClose={() => setCallLeaderOpen(false)}
        onComplete={() => {
          triggerSnackbar(`แจ้งหัวหน้าเรื่องใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCallLeaderOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`แจ้งหัวหน้าเรื่องใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseOrderProfileCancelDialog
        purchaseOrderId={id}
        purchaseOrderCode={code}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} สำเร็จ`)
          forceReload()
          setCancelOpen(false)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกใบสั่งซื้อเลขที่ ${code} ไม่สำเร็จ`)
        }}
      />
      <Button variant="contained" size="large" onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={menuOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showedEditMenuItem && (
          <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
            <MenuItem
              disabled={!writePermission.hasPermission}
              onClick={() => history.push(`/purchase-orders/${id}/update`)}
            >
              แก้ไขใบสั่งซื้อ
            </MenuItem>
          </ConditionTooltip>
        )}
        {showedCancelMenuItem && (
          <MultipleConditionTooltip conditionItems={cancelConditions}>
            <MenuItem disabled={cancelConditions.some((cond) => cond.showTooltip)} onClick={() => setCancelOpen(true)}>
              ยกเลิกใบสั่งซื้อ
            </MenuItem>
          </MultipleConditionTooltip>
        )}
        {showedCallLeaderMenuItem && (
          <ConditionTooltip showTooltip={!callLeaderPermission.hasPermission} title={callLeaderPermission.alertMessage}>
            <MenuItem disabled={!callLeaderPermission.hasPermission} onClick={() => setCallLeaderOpen(true)}>
              แจ้งหัวหน้า
            </MenuItem>
          </ConditionTooltip>
        )}
      </Popover>
    </FlexBox>
  )
}
