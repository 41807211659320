import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { useState } from 'react'
import {
  BaseData,
  IPaymentTransaction,
  PaymentRequisitionProfile,
  PaymentRequisitionState,
} from '../../../api/smartbestbuys-api'
import { EmptyCard, Title, TypographyWithLabel } from '../../../custom-components'
import { PaymentRequisitionStatus } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { dateTimeFormat } from '../../../functions'
import { ConditionTooltip, ImageCard } from '../../../shared-components'
import { getUserPermissionInfo } from '../../../utils/permission'
import ConfirmStatementDialog from './ConfirmStatementDialog'
import { IBankAccountStatement } from '../../../api/smartbestbuys-api/interfaces/bank-account'
import { formatNumber } from '../../../functions/number-utils'

const blacklistedStatusToUploadStatement = [
  PaymentRequisitionStatus.Success,
  PaymentRequisitionStatus.Rejected,
  PaymentRequisitionStatus.Canceled,
]

interface PaymentRequisitionInfo {
  id: number
  total: number
  status: BaseData
  state?: PaymentRequisitionState
  paymentTransaction?: Pick<
    IPaymentTransaction,
    'id' | 'code' | 'currency' | 'totalAmount' | 'exchangeRate' | 'status' | 'bankAccount'
  > & {
    bankAccountStatement?: Pick<
      IBankAccountStatement,
      'id' | 'amount' | 'remark' | 'statementTimestamp' | 'bankAccount'
    >
  }
}

interface Props {
  paymentRequisitionInfo: PaymentRequisitionInfo
  // TODO: should not return whole profile
  onUploadStatementSuccess?: (data: PaymentRequisitionProfile) => void
}

const PaymentRequisitionStatementBox: React.FC<Props> = (props: Props) => {
  // props
  const { paymentRequisitionInfo, onUploadStatementSuccess } = props
  const { state, paymentTransaction } = paymentRequisitionInfo
  // state
  const [uploadStatementOpen, setUploadStatementOpen] = useState(false)
  const statementInfo = state?.statementInfo

  const showedReUploadStatement =
    !blacklistedStatusToUploadStatement.includes(paymentRequisitionInfo.status.id) &&
    !!statementInfo &&
    !paymentTransaction?.bankAccountStatement?.id

  const bankAccountStatement = paymentTransaction?.bankAccountStatement
  const bankAccountText = bankAccountStatement
    ? `${bankAccountStatement.bankAccount.bank.name} ${bankAccountStatement.bankAccount.accountName} ${bankAccountStatement.bankAccount.accountNumber}`
    : ''

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write)

  return (
    <Box p={3} clone>
      <Paper>
        <ConfirmStatementDialog
          open={uploadStatementOpen}
          onClose={() => setUploadStatementOpen(false)}
          paymentRequisitionInfo={{
            id: paymentRequisitionInfo.id,
            finalTotal: paymentRequisitionInfo.total,
          }}
          onSuccess={(data) => {
            if (!!onUploadStatementSuccess) onUploadStatementSuccess(data)
            setUploadStatementOpen(false)
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} container justify="space-between">
            <Title>ผลการตรวจสอบ Statement</Title>
            {!!showedReUploadStatement && (
              <ConditionTooltip showTooltip={!writePermission.hasPermission} title={writePermission.alertMessage}>
                <IconButton
                  disabled={!writePermission.hasPermission}
                  aria-label="re upload payslip"
                  color="secondary"
                  onClick={() => setUploadStatementOpen(true)}
                >
                  <Edit />
                </IconButton>
              </ConditionTooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            {!paymentRequisitionInfo.paymentTransaction?.id && !!statementInfo ? (
              <Grid container spacing={1}>
                {!!statementInfo.statementImageUrl && (
                  <Grid item xs={12} container justify="center">
                    <ImageCard size="big" name="payslip" src={statementInfo.statementImageUrl} />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วัน-เวลาที่ออกเช็ค:">
                    {dateTimeFormat(statementInfo.withdrawalAt)}
                  </TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="จำนวนเงิน:">
                    {formatNumber(statementInfo.withdrawalAmount)}
                  </TypographyWithLabel>
                </Grid>
              </Grid>
            ) : !!paymentRequisitionInfo.paymentTransaction?.id && !!bankAccountStatement ? (
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วัน-เวลาที่ออกเช็ค:">
                    {dateTimeFormat(bankAccountStatement.statementTimestamp)}
                  </TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* use total of paymentTransaction because 1 statement can multiple payment transaction */}
                  <TypographyWithLabel label="จำนวนเงิน:">
                    {formatNumber(Math.abs(bankAccountStatement.amount))}
                  </TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="บัญชีธนาคาร:">{bankAccountText}</TypographyWithLabel>
                </Grid>
              </Grid>
            ) : (
              <EmptyCard error emptyText="ยังไม่ได้ตรวจสอบ Statement" />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default PaymentRequisitionStatementBox
