export enum TaskStatus {
  Pending = 1,
  Processing = 2,
  Done = 200,
  Failed = 400,
  PreCancel = 998,
  Canceled = 999,
  TransportPending = 3100,
  TransportDataPrepared = 3101,
  ReadyToShip = 3200,
  Shipping = 3201,
  PendingApproval = 3300,
}

export enum TaskType {
  Unknown = 0,
  TransportScheduleTask = 3001,
  TransportPickupTask = 3002,

  WarehousePrepareProduct = 4001,
  WarehouseReclaimProduct = 4002,
  WarehousePrepareProductFrontShop = 4003,
  WaitingCustomerShipProduct = 4005,
  WaitingCustomerReturnProduct = 4006,
  WarehouseReceiveProductTask = 4007,

  FinancePrepareDocument = 5001,
  FinanceReturnDocument = 5002,
  MessengerShippingTask = 6001,
  MessengerPickupTask = 6002,

  // Purchase Order
  WarehouseReceivedProduct = 4004,
  FollowServiceTask = 7001,
}

export enum TransportShippingTaskFinishAction {
  Success = 1,
  Fail = 2,
}
