import { PRCreationRequest, PRProductForUpsert } from '../../../api/smartbestbuys-api'
import { PurchaseRequisitionType } from '../../../enums'
import { dateFormat } from '../../../functions'
import PurchaseRequisitionCreateFormValues from './PurchaseRequisitionCreateFormValue'

export const mapPurchaseRequisitionsToCreationFormValues = (
  value: PurchaseRequisitionCreateFormValues,
  type: PurchaseRequisitionType,
  saleOrderId?: number,
): PRCreationRequest => {
  const total = value.products.reduce<number | undefined>((sum, product) => {
    if (sum === undefined || !product.price) return undefined
    return sum + product.price * product.amount
  }, 0)

  const products = value.products.map<PRProductForUpsert>((product) => ({
    id: product.id,
    amount: product.amount,
    price: !!product.price ? product.price : undefined,

    base64: product.base64,
    imageUrl: product.imageUrl,
    code: product.code,
    name: product.name,
    unit: product.unit,
    typeId: product.type?.id || product.typeId!,
  }))

  const attachments = value.attachments?.map((attachment) => {
    return {
      base64: attachment.base64!,
    }
  })

  return {
    ...value,
    typeId: type,
    dueDate: value.dueDate && dateFormat(value.dueDate, 'yyyy-MM-dd'),
    attachments,
    total,
    products,
    referenceVendorId: value.vendor?.id,
    saleOrderId,
  }
}
