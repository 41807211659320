import React, { useContext, useEffect, useState, Fragment } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Button,
  Typography,
  InputAdornment,
  Card,
  MenuItem,
  Checkbox,
  Select,
  Chip,
  InputLabel,
  FormControl,
  ListItemText,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import {
  FlexBox,
  GeneralTextFieldForm,
  KeyboardDateTimePickerForm,
  TextFieldForm,
} from '../../../../../../custom-components'
import { DocumentStatus, PaymentMethod, SOPaymentTransactionStatus } from '../../../../../../enums'
import SaleOrderProfileContext from '../../SaleOrderProfileContext'
import { Loading, SwitchBox, ButtonRadios, UploadImageCard } from '../../../../../../shared-components'
import { paymentMethods, paymentAdjustments } from '../../../../../../types'
import _ from 'lodash'
import numeral from 'numeral'
import { bankAccounts, banks } from '../../../../../../data'
import { SOPaymentFormValue } from './types'
import { makePayment } from './service'
import { RootContext } from '../../../../../..'
import { PAYMENT_CONFIRMED_TEXT } from '../../../../../../contants'
import { roundNumber } from '../../../../../../functions/number-utils'

const SOPaymentFormDialog: React.FC<DialogProps> = (props) => {
  const { onClose } = props
  const { triggerSnackbar } = useContext(RootContext)
  const { saleOrderProfile, reload } = useContext(SaleOrderProfileContext)
  const methods = useForm<SOPaymentFormValue>({
    defaultValues: {
      paymentMethodId: PaymentMethod.Cash,
      hasPaymentAdjustment: false,
      hasWithhodingTax: false,
    },
  })

  const {
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    register,
    unregister,
  } = methods

  const {
    hasPaymentAdjustment,
    paymentMethodId,
    hasWithhodingTax,
    amount,
    paymentAdjustmentAmount = 0,
    withholdingAmount = 0,
    withholdingTaxDocumentReceived = false,
  } = watch()

  useEffect(() => {
    if (paymentMethodId === PaymentMethod.Transfer || paymentMethodId === PaymentMethod.Cheque) {
      register('payslipBase64', { required: { value: true, message: 'กรุณาแนบรูปหลักฐาน' } })
    }

    return () => {
      unregister('payslipBase64')
    }
  }, [paymentMethodId, register, unregister])

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([])

  if (!saleOrderProfile) return <Loading />

  const { paymentTransactions, total, documents } = saleOrderProfile

  const selectableDocuments = documents?.filter(
    (d) => [DocumentStatus.Pending, DocumentStatus.Sent].includes(d.status.id) && !d.paymentTransaction?.id,
  )

  const calcucateWithPercent = (total: number, percent: number) => roundNumber((total * percent) / 100)

  let paidAmount = 0
  if (paymentTransactions) {
    paidAmount = _.sum(
      paymentTransactions
        .filter(
          (pt) =>
            pt.status.id !== SOPaymentTransactionStatus.DECLINED &&
            pt.status.id !== SOPaymentTransactionStatus.CANCELLED,
        )
        .map((pt) => pt.amount),
    )
  }

  const remainingAmount = total - paidAmount

  const priceBreakdowns = [
    { label: 'ยอดเต็ม', value: total },
    { label: 'ยอดค้างชำระ', value: remainingAmount },
    { label: 'ยอดชำระ', value: amount },
    { label: 'รายการปรับลด', value: paymentAdjustmentAmount },
    { label: 'หัก ณ ที่จ่าย', value: withholdingAmount },
    { label: 'ยอดจริงที่ได้รับ', value: amount - paymentAdjustmentAmount - withholdingAmount },
  ]

  const paymentOptions = [
    paidAmount > 0 ? { label: 'ชำระยอดค้าง', value: remainingAmount } : { label: 'ชำระเต็ม', value: total },
    { label: '30%', value: calcucateWithPercent(total, 30) },
    { label: '50%', value: calcucateWithPercent(total, 50) },
    { label: '70%', value: calcucateWithPercent(total, 70) },
  ]

  const onSubmit = async (values: SOPaymentFormValue) => {
    const response = await makePayment(saleOrderProfile.id, values)
    if (response?.status === 201) {
      // TODO: reload SO PROFILE
      triggerSnackbar('จ่ายเงินสำเร็จ')
      reload()
      reset()
      setSelectedDocumentIds([])
      onClose && onClose({}, 'escapeKeyDown')
    } else {
      triggerSnackbar('จ่ายเงินไม่สำเร็จ')
    }
    return true
  }

  const renderForm = (paymentMethodId: PaymentMethod) => {
    const forms = {
      [PaymentMethod.Credit]: <CreditCardForm />,
      [PaymentMethod.Transfer]: <TransferForm />,
      [PaymentMethod.Cheque]: <ChequeForm />,
      [PaymentMethod.Cash]: <></>,
    }

    return forms[paymentMethodId]
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    clearErrors()
    setSelectedDocumentIds(event.target.value as number[])
    setValue('refDocumentIds', event.target.value as number[])
  }

  return (
    <Dialog {...props} fullWidth maxWidth="md">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>ชำระเงิน</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FlexBox flexDirection="column" gridGap={8}>
                      <GeneralTextFieldForm
                        control={control}
                        type="number"
                        inputProps={{
                          step: '0.01',
                        }}
                        name="amount"
                        rules={{
                          required: { value: true, message: 'กรุณาใส่ยอดชำระ' },
                          max: { value: remainingAmount, message: 'ยอดเงินเกินยอดค้างชำระ' },
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                        }}
                      />
                      <FlexBox gridGap={8} alignItems="center">
                        <ButtonRadios
                          label=""
                          value={amount}
                          items={paymentOptions.map((po) => ({ id: po.value, name: po.label }))}
                          onChange={(value) => setValue('amount', value)}
                        />
                        <Typography variant="caption" color="textSecondary">
                          (คิดเป็น % จากยอดเต็ม)
                        </Typography>
                      </FlexBox>
                    </FlexBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchBox title="รายการปรับลด" onToggle={(enabled) => setValue('hasPaymentAdjustment', enabled)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ButtonRadios
                            label="ประเภทการปรับลด *"
                            onChange={(paymentAdjustmentId) => setValue('paymentAdjustmentTypeId', paymentAdjustmentId)}
                            items={paymentAdjustments}
                            defaultValue={PaymentMethod.Cash}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <GeneralTextFieldForm
                            control={control}
                            type="number"
                            inputProps={{
                              step: '0.01',
                            }}
                            name="paymentAdjustmentAmount"
                            rules={{ required: { value: hasPaymentAdjustment, message: 'กรุณาระบุรายการปรับลด' } }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SwitchBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchBox title="หัก ณ ที่จ่าย" onToggle={(enabled) => setValue('hasWithhodingTax', enabled)}>
                      <GeneralTextFieldForm
                        control={control}
                        type="number"
                        inputProps={{
                          step: '0.01',
                        }}
                        name="withholdingAmount"
                        label="จำนวนเงิน *"
                        rules={{ required: { value: hasWithhodingTax, message: 'กรุณาระบุภาษีหัก ณ ​ที่จ่าย' } }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">บาท</InputAdornment>,
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={withholdingTaxDocumentReceived}
                            onChange={() => {
                              setValue('withholdingTaxDocumentReceived', !withholdingTaxDocumentReceived)
                            }}
                          />
                        }
                        label="ได้รับเอกสารหัก​ ณ ที่จ่ายแล้ว"
                      />
                    </SwitchBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonRadios
                      label="วิธีการชำระเงิน *"
                      onChange={(paymentMethodId) => {
                        reset({
                          ...getValues(),
                          paymentMethodId,
                          paymentTimestamp: undefined,
                          bankAccountId: undefined,
                          payslipBase64: undefined,
                          referenceNumber: undefined,
                          branchName: undefined,
                          bankId: undefined,
                        })
                        clearErrors()
                      }}
                      items={paymentMethods}
                      defaultValue={paymentMethodId}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {renderForm(paymentMethodId)}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small" variant="outlined" error={!!errors.refDocumentIds}>
                      <InputLabel style={{ backgroundColor: 'white' }}>เอกสารที่เกี่ยวข้อง</InputLabel>
                      <Select
                        multiple
                        onChange={handleChange}
                        value={selectedDocumentIds}
                        renderValue={(selected) => (
                          <FlexBox gridGap={4} flexWrap="wrap">
                            {documents
                              ?.filter((d) => (selected as number[]).includes(d.id))
                              .map((d) => (
                                <Chip key={d.id} label={`${d.type.name} ${d.code}`} size="small" />
                              ))}
                          </FlexBox>
                        )}
                      >
                        {!!selectableDocuments && selectableDocuments.length > 0 ? (
                          selectableDocuments.map((d) => (
                            <MenuItem key={d.id} value={d.id}>
                              <Checkbox checked={selectedDocumentIds.indexOf(d.id) > -1} color="primary" />
                              <ListItemText primary={`${d.type.name} ${d.code}`} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key="no-document" disabled>
                            <Typography variant="body2" color="textSecondary">
                              ไม่มีเอกสารที่สามารถเลือกได้
                            </Typography>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <FlexBox flexDirection="column" gridGap>
                  <Typography variant="body2">สรุปการชำระเงิน</Typography>
                  <Card variant="outlined">
                    <Grid item xs={12} container spacing={2}>
                      {priceBreakdowns.map((pb) => (
                        <Fragment key={pb.label}>
                          <Grid item xs={6}>
                            <Typography variant="body2" align="right" display="block">
                              {pb.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" align="right" display="block">
                              {numeral(pb.value).format('0,0.00')}
                            </Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Card>
                  <GeneralTextFieldForm
                    control={control}
                    name="confirmationText"
                    label="พิมพ์ “ยอดเงินถูกต้อง” เพื่อยืนยันข้อมูล"
                    rules={{
                      required: { value: true, message: 'กรุณายืนยันข้อมูล' },
                      validate: {
                        correctText: (value) => value === PAYMENT_CONFIRMED_TEXT,
                      },
                    }}
                    error={!!errors.confirmationText}
                    helperText={
                      !!errors.confirmationText ? errors.confirmationText.message || 'กรุณากรอกให้ถูกต้อง' : undefined
                    }
                    autoComplete="off"
                  />
                </FlexBox>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose && onClose({} as any, 'backdropClick')}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress /> : undefined}
            >
              ยืนยันข้อมูล
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default SOPaymentFormDialog

const CreditCardForm: React.FC = (props) => {
  const { setValue } = useFormContext<SOPaymentFormValue>()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="center">
          <UploadImageCard
            previewOnChange
            title="อัปโหลดรูปหลักฐาน (ถ้ามี)"
            onChange={(image) => setValue('payslipBase64', image.base64)}
          />
        </FlexBox>
      </Grid>
    </Grid>
  )
}

const TransferForm: React.FC = (props) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<SOPaymentFormValue>()
  const { paymentMethodId, paymentTimestamp } = watch()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="center">
          <UploadImageCard
            previewOnChange
            title="อัปโหลดรูปหลักฐาน *"
            onChange={(image) => setValue('payslipBase64', image.base64)}
            error={!!errors.payslipBase64}
            message={errors.payslipBase64?.message}
          />
        </FlexBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <KeyboardDateTimePickerForm
          control={control}
          label="เลือกวันและเวลาที่โอนเงิน *"
          name="paymentTimestamp"
          value={paymentTimestamp}
          defaultValue={null}
          rules={{
            required: { value: paymentMethodId === PaymentMethod.Transfer, message: 'กรุณาเลือกระบุวันเวลาโอน' },
          }}
          size="small"
          fullWidth
          error={!!errors.paymentTimestamp}
          helperText={errors.paymentTimestamp?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldForm
          select
          fullWidth
          control={control}
          name="bankAccountId"
          label="บัญชีธนาคารสำหรับโอนเข้า *"
          variant="outlined"
          size="small"
          type="number"
          rules={{ required: { value: paymentMethodId === PaymentMethod.Transfer, message: 'กรุณาเลือกบัญชีธนาคาร' } }}
          error={!!errors.bankAccountId}
          helperText={errors.bankAccountId?.message}
        >
          {bankAccounts.map((c) => (
            <MenuItem key={c.name} value={c.id}>
              <FlexBox flexDirection="column">
                <div>{c.bankName}</div>
                <div>{c.name}</div>
                <Typography color="primary">{c.number}</Typography>
              </FlexBox>
            </MenuItem>
          ))}
        </TextFieldForm>
      </Grid>
    </Grid>
  )
}

const ChequeForm: React.FC = (props) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<SOPaymentFormValue>()
  const { paymentTimestamp, paymentMethodId } = watch()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FlexBox justifyContent="center">
          <UploadImageCard
            previewOnChange
            title="อัปโหลดรูปหลักฐาน *"
            onChange={(image) => setValue('payslipBase64', image.base64)}
            error={!!errors.payslipBase64}
            message={errors.payslipBase64?.message}
          />
        </FlexBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldForm
          control={control}
          fullWidth
          name="referenceNumber"
          variant="outlined"
          color="primary"
          label="เลขที่เช็ค *"
          size="small"
          rules={{ required: { value: paymentMethodId === PaymentMethod.Cheque, message: 'กรุณาระบุเลขที่เช็ค' } }}
          error={!!errors.referenceNumber}
          helperText={errors.referenceNumber?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <KeyboardDateTimePickerForm
          control={control}
          label="เลือกวันและเวลาที่จ่ายเช็ค *"
          name="paymentTimestamp"
          value={paymentTimestamp}
          defaultValue={null}
          // inputVariant="outlined"
          size="small"
          fullWidth
          rules={{ required: { value: paymentMethodId === PaymentMethod.Cheque, message: 'กรุณาระบุวันเวลา' } }}
          error={!!errors.paymentTimestamp}
          helperText={errors.paymentTimestamp?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          select
          control={control}
          name="bankId"
          label="ธนาคาร *"
          type="number"
          rules={{ required: { value: paymentMethodId === PaymentMethod.Cheque, message: 'กรุณาเลือกบัญชีธนาคาร' } }}
          error={!!errors.bankId}
          helperText={errors.bankId?.message}
        >
          {banks.map((c) => (
            <MenuItem key={c.name} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldForm
          control={control}
          fullWidth
          name="branchName"
          variant="outlined"
          color="primary"
          label="สาขา *"
          size="small"
          rules={{ required: { value: paymentMethodId === PaymentMethod.Cheque, message: 'กรุณาระบุสาขา' } }}
          error={!!errors.branchName}
          helperText={errors.branchName?.message}
        />
      </Grid>
    </Grid>
  )
}
