import { useContext, useMemo, useState } from 'react'
import { FilteredTable, FlexBox, Grid, Link, Topic, withLayout } from '../../../custom-components'
import { PageName } from '../../../enums'
import { RootContext } from '../../..'
import { Box, TableCell, TableRow, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { SOPaymentTransactionDialog, SOStatusChip, Space } from '../../../shared-components'
import { useSaleOrderDailyPayment } from '../../../hooks/sales'
import { formatNumber } from '../../../functions/number-utils'
import {
  ISaleOrderDailyPaymentTableData,
  ISaleOrderDailyPaymentTransaction,
} from '../../../api/smartbestbuys-api/sale/sale-types'
import { DateTime } from 'luxon'
import { defaultToday } from '../../../data'
import { isDateBetween } from '../../../utils/date-utils'
import { dateTimeFormat } from '../../../functions'

interface SaleOrderDailyPaymentTransaction {
  saleOrderId: number
  saleOrderTotal: number
  soStatusId: number
  paymentTransaction: ISaleOrderDailyPaymentTransaction
}

const SaleOrderDailyPaymentPage = () => {
  // root context
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SALE_ORDER_DAILY_PAYMENT)

  // state
  const [paymentDate, setPaymentDate] = useState(defaultToday)
  const startOfPaymentDate = useMemo(() => DateTime.fromJSDate(paymentDate).startOf('day').toJSDate(), [paymentDate])

  const { data, summary } = useSaleOrderDailyPayment(DateTime.fromJSDate(paymentDate))

  const [selected, setSelected] = useState<SaleOrderDailyPaymentTransaction | undefined>(undefined)

  const handleSelectTransaction = (item: SaleOrderDailyPaymentTransaction) => setSelected(item)
  const handleCloseDialog = () => setSelected(undefined)

  return (
    <Box p={2}>
      {selected && (
        <SOPaymentTransactionDialog
          open={true}
          onClose={handleCloseDialog}
          saleOrderId={selected.saleOrderId}
          soStatusId={selected.soStatusId}
          transaction={{
            ...selected.paymentTransaction,
            recCreatedAt: new Date(selected.paymentTransaction.recCreatedAt),
            rejectedAt: selected.paymentTransaction.rejectedAt
              ? new Date(selected.paymentTransaction.rejectedAt)
              : undefined,
          }}
          total={selected.saleOrderTotal}
          readOnly
        />
      )}
      <FlexBox justifyContent="space-between" alignItems="center">
        <Topic>รายงานกระแสเงินสดรับรายวัน</Topic>
        <KeyboardDatePicker
          size="small"
          label="วันที่"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={paymentDate}
          onChange={(date) => date && setPaymentDate(date)}
        />
      </FlexBox>
      <Space />

      <Box bgcolor="white">
        <FilteredTable<ISaleOrderDailyPaymentTableData>
          downloadAsCsv={true}
          csvFilename={`รายงานกระแสเงินสดรับรายวันประจำวันที่${paymentDate.toDateString()}.csv`}
          disableDateRangeFilter
          columns={[
            {
              name: 'ช่องทางการมอบสินค้า',
              render: (item) => (
                <div style={{ width: 80 }}>
                  <Grid item xs={12}>
                    {item.customerPickUpMethod.name}
                  </Grid>
                </div>
              ),
              csvRender: (item) => item.customerPickUpMethod.name,
            },
            {
              name: 'สถานะการส่งสินค้า',
              render: (item) => <SOStatusChip status={item.status.id} label={item.status.name} />,
              csvRender: (item) => item.status.name,
            },
            {
              name: 'SO',
              dataIndex: 'code',
              render: (item) => (
                <Link color="primary" to={`/sale-orders/${item.id}`}>
                  {item.code}
                </Link>
              ),
              csvRender: (item) => item.code,
            },
            {
              name: 'ชื่อลูกค้า',
              dataIndex: 'customer',
              render: (item) => (
                <div style={{ width: 140 }}>
                  <Typography className="displayInline" variant="body2">
                    {item.customer.name}
                  </Typography>
                </div>
              ),
              csvRender: (item) => item.customer.name,
            },
            {
              name: 'รายได้อ้างอิง',
              render: (item) =>
                item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((t) => {
                    return (
                      <div key={t.code}>
                        <Typography
                          style={{ cursor: 'pointer' }}
                          variant="body2"
                          color="primary"
                          onClick={() =>
                            handleSelectTransaction({
                              saleOrderId: item.id,
                              saleOrderTotal: item.paymentTotalAmount,
                              soStatusId: item.status.id,
                              paymentTransaction: t,
                            })
                          }
                        >
                          {t.code}
                        </Typography>
                      </div>
                    )
                  }),
              csvRender: (item) =>
                item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((t) => `${t.code},`),
            },
            {
              name: 'ใบกำกับภาษี',
              render: (item) => {
                return !(item.taxInvoices.length === 0) ? (
                  item.taxInvoices.map((doc) => {
                    return (
                      <div>
                        <Typography key={doc.code} variant="body2">
                          {doc.code}
                        </Typography>
                      </div>
                    )
                  })
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    ไม่มีเอกสาร
                  </Typography>
                )
              },
              csvRender: (item) =>
                item.taxInvoices.length !== 0 ? item.taxInvoices.map((doc) => doc.code) : 'ไม่มีเอกสาร',
            },
            {
              name: 'ใบวางบิล',
              render: (item) => {
                return !(item.billingNotes.length === 0) ? (
                  item.billingNotes.map((doc) => {
                    return (
                      <div>
                        <Typography key={doc.code} variant="body2">
                          {doc.code}
                        </Typography>
                      </div>
                    )
                  })
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    ไม่มีเอกสาร
                  </Typography>
                )
              },
              csvRender: (item) =>
                item.billingNotes.length !== 0 ? item.billingNotes.map((doc) => doc.code) : 'ไม่มีเอกสาร',
            },
            {
              name: 'ใบเสร็จรับเงิน',
              render: (item) => {
                return !(item.recieptNotes.length === 0) ? (
                  item.recieptNotes.map((doc) => {
                    return (
                      <div>
                        <Typography key={doc.code} variant="body2">
                          {doc.code}
                        </Typography>
                      </div>
                    )
                  })
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    ไม่มีเอกสาร
                  </Typography>
                )
              },
              csvRender: (item) =>
                item.recieptNotes.length !== 0 ? item.recieptNotes.map((doc) => doc.code) : 'ไม่มีเอกสาร',
            },
            { name: 'ยอดค้างชำระ', dataIndex: 'remainingAmount', numeric: true, format: '0,0.00' },
            {
              name: 'วิธีการชำระเงิน',
              render: (item) => {
                return item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((transaction) => {
                    return (
                      <div key={transaction.code} style={{ width: 90 }}>
                        <Typography variant="body2">{transaction.paymentMethod.name}</Typography>
                      </div>
                    )
                  })
              },
              csvRender: (item) => {
                return item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((transaction) => `${transaction.paymentMethod.name},`)
              },
            },
            {
              name: 'ยอดที่ได้รับชำระ',
              align: 'right',
              render: (item) => {
                return item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((transaction) => {
                    return (
                      <div key={transaction.code}>
                        <Typography variant="body2">{formatNumber(transaction.amount)}</Typography>
                      </div>
                    )
                  })
              },
              csvRender: (item) => {
                return item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((transaction) => `${formatNumber(transaction.amount)},`)
              },
            },
            // {
            //   hide: true,
            //   name: 'โอน',
            //   render: (item) => {
            //     return item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .map((payment) => {
            //         return payment.paymentMethod.id === PaymentMethod.Transfer ? (
            //           <div key={payment.code}>
            //             <Typography variant="body2">{formatNumber(payment.amount)}</Typography>
            //           </div>
            //         ) : (
            //           <div key={payment.code}>
            //             <Typography variant="body2" color="textSecondary">
            //               -
            //             </Typography>
            //           </div>
            //         )
            //       })
            //   },
            //   csvRender: (item) =>
            //     item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .filter((payment) => payment.paymentMethod.id === PaymentMethod.Transfer)
            //       .reduce<number>((summary, payment) => {
            //         return (summary += Number(payment.amount))
            //       }, 0),
            // },
            // {
            //   hide: true,
            //   name: 'เช็ค',
            //   render: (item) => {
            //     return item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .map((payment) => {
            //         return payment.paymentMethod.id === PaymentMethod.Cheque ? (
            //           <div key={payment.code}>
            //             <Typography variant="body2">{formatNumber(payment.amount)}</Typography>
            //           </div>
            //         ) : (
            //           <div key={payment.code}>
            //             <Typography variant="body2" color="textSecondary">
            //               -
            //             </Typography>
            //           </div>
            //         )
            //       })
            //   },
            //   csvRender: (item) =>
            //     item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .filter((payment) => payment.paymentMethod.id === PaymentMethod.Cheque)
            //       .reduce<number>((summary, payment) => {
            //         return (summary += Number(payment.amount))
            //       }, 0),
            // },
            // {
            //   hide: true,
            //   name: 'บัตรเครดิต',
            //   render: (item) => {
            //     return item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .map((payment) => {
            //         return payment.paymentMethod.id === PaymentMethod.Credit ? (
            //           <div key={payment.code}>
            //             <Typography variant="body2">{formatNumber(payment.amount)}</Typography>
            //           </div>
            //         ) : (
            //           <div key={payment.code}>
            //             <Typography variant="body2" color="textSecondary">
            //               -
            //             </Typography>
            //           </div>
            //         )
            //       })
            //   },
            //   csvRender: (item) =>
            //     item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .filter((payment) => payment.paymentMethod.id === PaymentMethod.Credit)
            //       .reduce<number>((summary, payment) => {
            //         return (summary += Number(payment.amount))
            //       }, 0),
            // },
            // {
            //   hide: true,
            //   name: 'เงินสด',
            //   render: (item) => {
            //     return item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .map((payment) => {
            //         return payment.paymentMethod.id === PaymentMethod.Cash ? (
            //           <div key={payment.code}>
            //             <Typography variant="body2">{formatNumber(payment.amount)}</Typography>
            //           </div>
            //         ) : (
            //           <div key={payment.code}>
            //             <Typography variant="body2" color="textSecondary">
            //               -
            //             </Typography>
            //           </div>
            //         )
            //       })
            //   },
            //   csvRender: (item) =>
            //     item.paymentTransactions
            //       .filter((transaction) =>
            //         isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
            //       )
            //       .filter((payment) => payment.paymentMethod.id === PaymentMethod.Cash)
            //       .reduce<number>((summary, payment) => {
            //         return (summary += Number(payment.amount))
            //       }, 0),
            // },
            {
              name: 'รวมสุทธิ',
              align: 'right',
              render: (item) => (
                <Typography
                  variant="body2"
                  color={item.paymentTotalAmount < item.remainingAmount ? 'error' : 'textPrimary'}
                >
                  {formatNumber(item.paymentTotalAmount)}
                </Typography>
              ),
              csvRender: (item) => formatNumber(item.paymentTotalAmount),
            },
            {
              name: 'วันที่เงินเข้าบัญชี',
              numeric: true,
              render: (item) => {
                return item.paymentTransactions
                  .filter((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      confirmPaymentTimestamp &&
                      isDateBetween(new Date(confirmPaymentTimestamp), startOfPaymentDate, paymentDate)
                    )
                  })
                  .map((transaction) => {
                    const confirmPaymentTimestamp = transaction.statementTimestamp ?? transaction.paymentTimestamp
                    return (
                      <div key={transaction.code} style={{ width: 90 }}>
                        <Typography variant="body2">
                          {!!confirmPaymentTimestamp ? dateTimeFormat(confirmPaymentTimestamp) : '-'}
                        </Typography>
                      </div>
                    )
                  })
              },
              csvRender: (item) => {
                return item.paymentTransactions
                  .filter((transaction) =>
                    isDateBetween(new Date(transaction.recCreatedAt), startOfPaymentDate, paymentDate),
                  )
                  .map((transaction) =>
                    !!transaction.statementTimestamp ? dateTimeFormat(transaction.statementTimestamp) : '-',
                  )
              },
            },
          ]}
          postCells={
            <TableRow className="summary-row">
              <TableCell align="right" colSpan={7}>
                รวม
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  className="remaining-charge-amount"
                  color={summary.remainingAmount === 0 ? 'textPrimary' : 'error'}
                >
                  {formatNumber(summary.remainingAmount)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <div>โอน</div>
                <div>เช็ค</div>
                <div>บัตรเครดิต</div>
                <div>เงินสด</div>
              </TableCell>
              <TableCell align="right">
                <div>{formatNumber(summary.transfer)}</div>
                <div>{formatNumber(summary.cheque)}</div>
                <div>{formatNumber(summary.credit)}</div>
                <div>{formatNumber(summary.cash)}</div>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" className="remaining-charge-amount">
                  {formatNumber(summary.total)}
                </Typography>
              </TableCell>
            </TableRow>
          }
          data={data}
          color="primary.dark"
          size="small"
          pagination={false}
          filters={[
            {
              name: 'ช่องทางการส่งมอบสินค้า',
              values: [
                {
                  name: 'ส่งถึงที่',
                  value: 1,
                },
                {
                  name: 'นัดรับหน้าร้าน',
                  value: 2,
                },
                {
                  name: 'รับของทันที',
                  value: 3,
                },
              ],
              filter: (data: ISaleOrderDailyPaymentTableData, values: (string | number)[]) => {
                const customerPickUpMethodId = data.customerPickUpMethod.id ?? -1
                return values.includes(customerPickUpMethodId)
              },
            },
          ]}
          cleanSpace
        />
      </Box>
    </Box>
  )
}

export default withLayout(SaleOrderDailyPaymentPage, true)
