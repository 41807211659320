export enum ProductType {
  Product = 1,
  Service = 2,
  InternalProduct = 3,
  CompoundProduct = 4,
  Asset = 5,
}

export enum ProductTransactionType {
  In = 1,
  Out = 2,
}

export enum ProductMode {
  Available = 'available',
  Archive = 'archive',
}

export enum ProductGrade {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum ProductKarajicMatrix {
  Critical = 1,
  BotttleNeck = 2,
  Routine = 3,
  Leverage = 4,
}

export enum ProductUnitSize {
  Meter = 1000,
  Centimeter = 10,
  Millimeter = 1,
}

export enum ProductUnitWeight {
  KiloGram = 1000,
  Gram = 1,
}
