import React, { useEffect } from 'react'
import { Grid, InputAdornment, MenuItem, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

import { FlexBox, GeneralTextFieldForm, Title } from '../../../../custom-components'
import ProductCreationFormValue from '../ProductCreationFormValues'
import { ButtonRadios, UploadImageCard } from '../../../../shared-components'
import { BusinessUnit } from '../../../../api/smartbestbuys-api'
import { useBusinessCategoriesInfo } from '../hooks'
import { productKarajicMatrixs, sizeOfUnitProducts, weightOfUnitProducts } from '../../../../functions'
import { ProductUnitSize, ProductUnitWeight } from '../../../../enums'
import { productTypes } from '../../../../constants'

interface Props {
  businessUnits: BusinessUnit[]
  productStorages: any[]
  edit?: boolean
}

const ProductInformationInput: React.FC<Props> = (props) => {
  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
    register,
  } = useFormContext<ProductCreationFormValue>()

  const { businessId, typeOfCodeProduct, categoryProductId, typeOfProduct, leadTime, safetyStockPolicy, dealerPrice } =
    watch()

  const typesOfCodeProduct = [
    { label: 'อัตโนมัติ', value: 1 },
    { label: 'กำหนดเอง', value: 2 },
  ]
  const { businessUnits, productStorages, edit } = props

  useEffect(() => {
    register('typeOfProduct', {
      required: 'กรุณาเลือกรูปแบบสินค้า',
    })
  }, [register])

  const [categories, subCategories] = useBusinessCategoriesInfo(
    businessUnits,
    businessId ? Number(businessId) : undefined,
    categoryProductId ? Number(categoryProductId) : undefined,
  )

  const handleBusinessChange = () => {
    setValue('categoryProductId', '')
    setValue('subCategoryProductId', '')
  }

  const handleSubCategoryChange = (id: string | number) => {
    if (categoryProductId) return
    const subCategoryProduct = subCategories.find((subGroup) => subGroup.id === Number(id))
    setValue('categoryProductId', String(subCategoryProduct?.categoryId))
  }

  const handleCategoryChange = () => {
    setValue('subCategoryProductId', '')
  }

  return (
    <Grid container spacing={2}>
      {!edit && (
        <Grid item xs={12}>
          <Title>ข้อมูลสินค้า</Title>
        </Grid>
      )}
      <Grid container xs={12} justify="center" alignItems="center">
        <UploadImageCard
          previewOnChange
          title="อัปโหลดรูปสินค้า"
          onChange={(image) => setValue('productImageBase64', image.base64)}
          error={!!errors.productImageBase64}
          message={errors.productImageBase64?.message}
          defaultSrc={getValues('imageUrl')}
        />
      </Grid>
      {!edit && (
        <Grid item xs={12} md={6} alignItems="center">
          <ButtonRadios
            label="รูปแบบสินค้า *"
            items={productTypes.map((type) => ({ id: type.id, name: type.name }))}
            onChange={(value) => setValue('typeOfProduct', value)}
            errorMessage={errors.typeOfProduct?.message}
            value={typeOfProduct}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="businessId"
          defaultValue=""
          label="กลุ่มธุรกิจ"
          select
          onPostChange={handleBusinessChange}
        >
          {businessUnits.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>

      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="categoryProductId"
          defaultValue=""
          label="หมวดหมู่สินค้า"
          select
          disabled={!businessId}
          onPostChange={handleCategoryChange}
        >
          {categories.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="subCategoryProductId"
          defaultValue=""
          label="หมวดหมู่ย่อย"
          select
          disabled={!businessId}
          onPostChange={handleSubCategoryChange}
        >
          {subCategories.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>

      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="productNameTh"
          rules={{ required: 'กรุณากรอกชื่อสินค้า (ภาษาไทย)' }}
          type="text"
          label="ชื่อสินค้า (ภาษาไทย) *"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="productNameEn"
          type="text"
          label="ชื่อสินค้า (English)"
          defaultValue=""
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="productUnitTh"
          rules={{ required: 'กรุณากรอกหน่วยของสินค้า (ภาษาไทย)' }}
          type="text"
          label="หน่วยของสินค้า (ภาษาไทย) *"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="productUnitEn"
          type="text"
          label="หน่วยของสินค้า (English)"
          defaultValue=""
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="productWarranty"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">วัน</InputAdornment>,
          }}
          rules={{ required: 'กรุณากรอกระยะเวลาประกัน' }}
          type="number"
          label="ระยะเวลาประกัน *"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="storageProductId"
          defaultValue=""
          label="พื้นที่เก็บสินค้า"
          select
        >
          {productStorages.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="leadTime"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">วัน</InputAdornment>,
          }}
          rules={{ required: 'กรุณากรอกระยะการสั่งสินค้าเฉลี่ย' }}
          type="number"
          label="ระยะการสั่งสินค้าเฉลี่ย *"
          defaultValue={leadTime}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="safetyStockPolicy"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">วัน</InputAdornment>,
          }}
          rules={{ required: 'กรุณากรอกนโยบายเก็บ' }}
          type="number"
          label="นโยบายเก็บ *"
          defaultValue={safetyStockPolicy}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="dealerPrice"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          label="ราคาสำหรับ dealer"
          defaultValue={dealerPrice}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="brand" type="text" label="ยี่ห้อ" defaultValue="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="color" type="text" label="สี" defaultValue="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="material" type="text" label="วัสดุ" defaultValue="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm
          control={control}
          name="warrantyCondition"
          type="text"
          label="เงื่อนไขการรับประกันสินค้า"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm name="karajicMatrixId" select label="karajic matrix" control={control}>
          {productKarajicMatrixs.map((karajicMatrix) => (
            <MenuItem key={karajicMatrix.id} value={karajicMatrix.id}>
              {karajicMatrix.name}
            </MenuItem>
          ))}
        </GeneralTextFieldForm>
      </Grid>
      <Grid item xs={12}>
        <Title>ขนาด</Title>
      </Grid>
      <Grid item xs={12} md={6}>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            หน่วยของขนาด :
          </Grid>
          <Grid item xs={12} md={9}>
            <GeneralTextFieldForm
              name="unitSizeId"
              select
              label="หน่วยของขนาด"
              control={control}
              defaultValue={ProductUnitSize.Centimeter}
            >
              {sizeOfUnitProducts.map((size) => (
                <MenuItem key={size.id} value={size.id}>
                  {size.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
        </FlexBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="depthInMm" type="text" label="ยาว" defaultValue="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="widthInMm" type="text" label="กว้าง" defaultValue="" />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="heightInMm" type="text" label="สูง" defaultValue="" />
      </Grid>
      <Grid item xs={12}>
        <Title>น้ำหนัก</Title>
      </Grid>
      <Grid item xs={12} md={6}>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            หน่วยของน้ำหนัก :
          </Grid>
          <Grid item xs={12} md={9}>
            <GeneralTextFieldForm
              name="unitWeightId"
              select
              label="หน่วยของน้ำหนัก"
              defaultValue={ProductUnitWeight.Gram}
              control={control}
            >
              {weightOfUnitProducts.map((weight) => (
                <MenuItem key={weight.id} value={weight.id}>
                  {weight.name}
                </MenuItem>
              ))}
            </GeneralTextFieldForm>
          </Grid>
        </FlexBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralTextFieldForm control={control} name="weightInGram" type="text" label="น้ำหนัก" defaultValue="" />
      </Grid>

      {!edit && (
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            รหัสสินค้า *
          </Typography>
          <FlexBox gridGap={8} alignItems="flex-start" paddingBottom="10px">
            <ButtonRadios
              label=""
              items={typesOfCodeProduct.map((type) => ({ id: type.value, name: type.label }))}
              onChange={(value) => setValue('typeOfCodeProduct', value)}
              value={typeOfCodeProduct}
            ></ButtonRadios>
            {typeOfCodeProduct === 2 ? (
              <GeneralTextFieldForm
                control={control}
                name="customCodeProduct"
                rules={{ required: 'กรุณากรอกรหัสสินค้า' }}
                type="text"
                defaultValue=""
                margin="none"
                fullWidth={false}
              />
            ) : null}
          </FlexBox>
        </Grid>
      )}
    </Grid>
  )
}

export default ProductInformationInput
