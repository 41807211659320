export enum SaleOrderStatus {
  Pending = 101,
  PendingPayment = 201,
  PendingDepositPayment = 202,
  PendingDelivery = 301,
  Delivering = 302,
  WaitingCustomerPickUp = 303,
  Closed = 500,
  ClosedAtShop = 501,
  Cancelled = 999,
}

export enum SOPaymentStatus {
  Pending = 1,
  Deposit = 2,
  Paid = 3,
}

export enum SOPaymentTransactionStatus {
  PENDING = 1,
  APPROVED = 2,
  DECLINED = 3,
  CANCELLED = 4,
}

export enum SaleOrderFilterTable {
  InSufficientStock = 1,
  ReturnProductBack = 2,
  NeedToRefund = 3,
  NeedCreditNote = 4,
}

export enum ContributionOp {
  LessThan = 1, // %Sale > %Contribution
  Greater = 2, // %Sale < %Contribution
  Equals = 3, // %Sale = % Contribution
}
