/* eslint-disable react-hooks/rules-of-hooks */
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  MenuItem,
  Box,
} from '@material-ui/core'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { RootContext } from '../../..'
import {
  PurchaseOrderReceivedProductRequestBody,
  purchaseOrderReceiveProductAPI,
  ReceiveProductTask_PurchaseOrderInfo_Product,
} from '../../../api/smartbestbuys-api'
import {
  FlexBox,
  Form,
  GeneralTextFieldForm,
  KeyboardDatePickerForm,
  Table,
  TypographyWithLabel,
} from '../../../custom-components'
import { CurrenciesType } from '../../../enums'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { ReceiveProductAction } from '../../../enums/WarehousEnums'
import { dateFormat, dateSqlFormat } from '../../../functions'
import { formatNumberInt } from '../../../functions/number-utils'
import { Loading, PurchaseOrderStatusChip, Space, SubmitWithConfirmTextForm } from '../../../shared-components'
import { whitelistedStatusToPurchaseOrderReceiveProduct } from '../../../constants'
import PurchaseProductName from '../../purchase-orders/create/SelectedPurchaseOrderProductTable/PurchaseProductName'
import { usePurchaseOrderPendingReceiveProductById } from '../../../hooks/purchase/usePurchaseOrderPendingReceiveProductById'
import VendorProfileCard from '../../purchase-orders/profile/VendorProfileCard'

interface FormValues {
  receiveProductDetails: ReceiveProductDetail[]
  nextVendorShippingDate?: string
  exchangeRate?: number
  remark?: string
}

interface Props extends DialogProps {
  purchaseOrderId: number
  onComplete?: () => void
  onFailed?: () => void
}

const PendingReceiveProductDialog = (props: Props) => {
  // context
  const { triggerSnackbar } = useContext(RootContext)

  // props
  const { purchaseOrderId, onClose, onComplete, onFailed, ...otherProps } = props

  const purchaseOrder = usePurchaseOrderPendingReceiveProductById(purchaseOrderId, undefined)

  // hook form
  const formMethod = useForm<FormValues>()
  const {
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = formMethod

  const { receiveProductDetails, nextVendorShippingDate } = watch()
  const showNextVendorShippingDate = !!receiveProductDetails?.find(
    (p) =>
      !!p.receiveAction &&
      (p.receiveAction === ReceiveProductAction.Partial || p.receiveAction === ReceiveProductAction.Nothing),
  )

  useEffect(() => {
    if (!showNextVendorShippingDate) setValue('nextVendorShippingDate', undefined)
  }, [showNextVendorShippingDate, setValue])

  // effect
  useEffect(() => {
    if (!!purchaseOrder) {
      const initReceiveProductDetails = purchaseOrder.products.map((product) => {
        return {
          id: product.id,
          code: product.code,
          name: product.name,
          imageUrl: product.productImageUrl,
          amount: product.amount,
          unit: product.unit,
          receivedAmount: product.receivedAmount,
          expectedReceivedAmount: Math.max(product.amount - product.receivedAmount, 0),
        } as ReceiveProductDetail
      })
      // .filter((product) => product.expectedReceivedAmount > 0)
      reset({ receiveProductDetails: initReceiveProductDetails })
    }
  }, [purchaseOrder, reset])

  // loading
  if (!purchaseOrder)
    return (
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Loading />
      </Dialog>
    )

  // handler
  const handleSubmit = async (values: FormValues) => {
    const requestBody: PurchaseOrderReceivedProductRequestBody = {
      nextVendorShippingDate: values.nextVendorShippingDate
        ? dateSqlFormat(values.nextVendorShippingDate, 'yyyy-MM-dd')
        : undefined,
      remark: values.remark,
      exchangeRate: values.exchangeRate ?? 1,
      results: values.receiveProductDetails.map((p) => {
        const alreadyReceived = p.expectedReceivedAmount === 0
        return {
          id: p.id,
          name: p.name,
          code: p.code,
          imageUrl: p.imageUrl,
          unit: p.unit,
          action: alreadyReceived ? ReceiveProductAction.AlreadyReceived : p.receiveAction,
          receivedAmount: alreadyReceived ? 0 : p.toReceiveAmount,
          expectedAmount: p.expectedReceivedAmount,
        }
      }),
    }
    const response = await purchaseOrderReceiveProductAPI(purchaseOrder.id, requestBody)
    if (response?.status === 200) {
      triggerSnackbar('รับสินค้าสำเร็จ')
      if (!!onClose) onClose({}, 'backdropClick')
      if (!!onComplete) onComplete()
    } else {
      triggerSnackbar('รับสินค้าไม่สำเร็จ')
      if (!!onFailed) onFailed()
    }
    return true
  }

  const { code, vendorInfo, vendorShippingDate, status, saleOrderInfo } = purchaseOrder
  const isTHBCurrency = purchaseOrder.currency.id === CurrenciesType.THB
  const ableToReceive = whitelistedStatusToPurchaseOrderReceiveProduct.includes(purchaseOrder.status.id)

  return (
    <FormProvider {...formMethod}>
      <Dialog {...otherProps} fullWidth maxWidth="lg">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>
            {code} <Space direction="horizontal" />
            <Typography color="textSecondary">รับสินค้า</Typography>
          </DialogTitle>
          <DialogContent>
            <VendorProfileCard
              value={{
                id: vendorInfo.id,
                profileImageUrl: vendorInfo.profileImageUrl,
                code: vendorInfo.code,
                name: vendorInfo.name,
                referenceNumber: vendorInfo.referenceNumber,
                averageRating: vendorInfo.averageRating,
                phoneNumber: vendorInfo.phoneNumber,
                email: vendorInfo.email,
                websiteUrl: vendorInfo.websiteUrl,
                scopeWork: vendorInfo.scopeWork,
                remark: vendorInfo.remark,
                creditPeriodDay: vendorInfo.creditPeriodDay,
                currency: vendorInfo.currency,
                contactInfo: vendorInfo.contactInfo,
              }}
            />
            <Space />
            <Grid container>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel label="วันที่ส่งสินค้า:">
                  <Typography color="textSecondary">
                    {vendorShippingDate ? dateFormat(vendorShippingDate) : '-'}
                  </Typography>
                </TypographyWithLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TypographyWithLabel
                  label="ใบสั่งซื้ออ้างอิง:"
                  labelProps={{
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Typography color="textSecondary">
                    {code}
                    <Space direction="horizontal" />
                    <PurchaseOrderStatusChip status={status.id} label={status.name} />
                  </Typography>
                </TypographyWithLabel>
                {!ableToReceive && (
                  <FlexBox>
                    <Typography color="error" variant="caption">
                      ไม่สามารถรับของได้เนื่องจากสถานะของใบสั่งขายเป็น "{status.name}"
                    </Typography>
                  </FlexBox>
                )}
              </Grid>
              {saleOrderInfo && (
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="ใบสั่งขายอ้างอิง:">{saleOrderInfo.code}</TypographyWithLabel>
                </Grid>
              )}
            </Grid>
            <Space />
            <ReceiveProductTable />
            <Space />
            <Grid container spacing={2}>
              {showNextVendorShippingDate && (
                <Grid item md={6}>
                  <KeyboardDatePickerForm
                    control={control}
                    label="วันที่ส่งสินค้าใหม่ (ถ้ามี)"
                    name="nextVendorShippingDate"
                    size="small"
                    gutterTop
                    value={nextVendorShippingDate}
                    fullWidth
                    defaultValue={null}
                  />
                </Grid>
              )}
              {!isTHBCurrency && (
                <Grid item md={6}>
                  <GeneralTextFieldForm
                    control={control}
                    name="exchangeRate"
                    type="number"
                    label="อัตราการแลกเปลี่ยนเงิน *"
                    rules={{ required: 'กรุณากรอกอัตราการแลกเปลี่ยน' }}
                    defaultValue=""
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <GeneralTextFieldForm control={control} label="หมายเหตุ (ถ้ามี)" name="remark" fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitWithConfirmTextForm
              onCancel={() => {
                if (!!onClose) onClose({}, 'escapeKeyDown')
              }}
              isSubmitting={isSubmitting}
              disableSubmit={!ableToReceive}
              confirmTextOption={{
                veifiedText: 'จำนวนถูกต้องแน่นอน',
              }}
              submitPermission={PermissionEnum.Warehouse_Task_Write}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  )
}

export default PendingReceiveProductDialog

const grayscaleStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
}

interface ReceiveProductDetail extends ReceiveProductTask_PurchaseOrderInfo_Product {
  receiveAction: ReceiveProductAction
  toReceiveAmount: number
  expectedReceivedAmount: number
}

const ReceiveProductTable = () => {
  // state
  const { control, setValue, watch } = useFormContext<FormValues>()
  const { receiveProductDetails } = watch()

  // handler
  const handleReceiveActionChange = (value: ReceiveProductAction, index: number) => {
    const target = receiveProductDetails[index]
    target.receiveAction = value
    if (value === ReceiveProductAction.All) target.toReceiveAmount = target.expectedReceivedAmount
    if (value === ReceiveProductAction.Nothing) target.toReceiveAmount = 0
    setValue('receiveProductDetails', [
      ...receiveProductDetails.slice(0, index),
      target,
      ...receiveProductDetails.slice(index + 1),
    ])
  }

  return (
    <Table<ReceiveProductDetail>
      color="primary"
      size="small"
      data={receiveProductDetails}
      columns={[
        { name: 'ลำดับ', align: 'center', fixedWidth: 70, render: (_, index) => index + 1 },
        {
          name: 'ชื่อสินค้า',
          render: (product) => (
            <PurchaseProductName
              style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}
              imageUrl={product.imageUrl}
              code={product.code}
              name={product.name}
            />
          ),
        },
        {
          name: 'จำนวนที่เคยรับแล้ว/จำนวนตามใบสั่งซื้อ',
          fixedWidth: 170,
          align: 'right',
          render: (product) => (
            <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
              {formatNumberInt(product.receivedAmount)}/{formatNumberInt(product.amount)}
            </Typography>
          ),
        },
        {
          name: 'ผลการรับ',
          fixedWidth: 190,
          render: (product, index) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
              )
            const isFirstTime = product.receivedAmount === 0
            return (
              <GeneralTextFieldForm
                control={control}
                name={`receiveProductDetails.${index}.receiveAction`}
                select
                rules={{ required: 'กรุณาเลือกผลการรับ' }}
                onChange={(e) => !!e.target.value && handleReceiveActionChange(Number(e.target.value), index)}
              >
                <MenuItem value={ReceiveProductAction.All}>{isFirstTime ? 'รับครบ' : 'รับที่เหลือ'}</MenuItem>
                <MenuItem value={ReceiveProductAction.Partial}>รับขาด</MenuItem>
                <MenuItem value={ReceiveProductAction.OverWithGiven}>ได้ของแถม</MenuItem>
                {/* <MenuItem value={ReceiveProductAction.RejectRemaining}>รับขาดแต่ไม่รอรับที่ขาด</MenuItem> */}
                <MenuItem value={ReceiveProductAction.Nothing}>ไม่มีให้รับ</MenuItem>
              </GeneralTextFieldForm>
            )
          },
        },
        {
          name: 'จำนวนที่รับ',
          fixedWidth: 170,
          align: 'right',
          render: (product, index) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>-</Typography>
              )
            const ableToReceiveOverAmount = product.receiveAction === ReceiveProductAction.OverWithGiven
            if (
              product.receiveAction &&
              [
                ReceiveProductAction.Partial,
                ReceiveProductAction.OverWithGiven,
                ReceiveProductAction.RejectRemaining,
              ].includes(product.receiveAction)
            ) {
              return (
                <GeneralTextFieldForm
                  control={control}
                  name={`receiveProductDetails.${index}.toReceiveAmount`}
                  type="number"
                  rules={{
                    required: 'กรุณากรอกจำนวน',
                    min: { value: 1, message: 'รับอย่างน้อย 1 หน่วย' },
                    max: ableToReceiveOverAmount
                      ? undefined
                      : { value: product.expectedReceivedAmount, message: 'กรุณาอย่าใส่จำนวนเกินจำนวนที่ต้องรับ' },
                  }}
                />
              )
            }

            return '-'
          },
        },
        {
          name: 'จำนวนรับจริง/จำนวนที่ต้องรับ',
          align: 'right',
          fixedWidth: 140,
          render: (product) => {
            if (product.expectedReceivedAmount === 0)
              return (
                <Typography style={{ ...(product.expectedReceivedAmount === 0 ? grayscaleStyle : {}) }}>
                  รับครบไปแล้ว
                </Typography>
              )
            if (product.toReceiveAmount === undefined)
              return <Typography>?/{formatNumberInt(product.expectedReceivedAmount)}</Typography>
            let textColor = 'textPrimary'
            if (product.toReceiveAmount === product.expectedReceivedAmount) textColor = 'success.main'
            if (product.toReceiveAmount < product.expectedReceivedAmount) textColor = 'error.main'
            if (product.toReceiveAmount > product.expectedReceivedAmount) textColor = 'warning.main'
            return (
              <Box color={textColor} clone>
                <Typography>
                  {`${formatNumberInt(product.toReceiveAmount)}/${formatNumberInt(product.expectedReceivedAmount)}`}
                </Typography>
              </Box>
            )
          },
        },
      ]}
      cleanSpace
      pagination={false}
    />
  )
}
