import React, { createContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, Snackbar, CssBaseline } from '@material-ui/core'
import theme from './theme'
import { SOCreationPage } from './sales-system/pages'
import DateFnsUtils from '@date-io/date-fns'
import thLocale from 'date-fns/locale/th'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { checkSession } from './functions'
import {
  // HomePage,
  LoginPage,
  NotFoundPage,
  SaleOrderProfilePage,
  // SaleOrderUpdationPage,
  CustomerProfilePage,
  CustomerCreationPage,
  // DocumentInvoicePage,
  // DocumentTaxInvoicePage,
  // DocumentCreditNotePage,
  CreditNotePDFPage,
  WarehousePendingPickupTasksPage,
  TaxInvoicePDFPage,
  SaleOrderPDF,
  ProductProfilePage,
  ProductCreationPage,
  // ReceiptListPage,
  ReceiptPDFPage,
  // BillingNoteListPage,
  BillingNotePDFPage,
  InvoicePDFPage,
  PurchaseRequisitionCreatePage,
  PurchaseRequisitionListPage,
  SalesDebtorsPage,
  PurchaseRequisitionProfilePage,
  PurchaseRequisitionUpdatePage,
  // PurchaseOrderListPage,
  PurchaseOrderCreatePage,
  PaymentRequisitionListPage,
  PurchasePaymentListPage,
  PaymentRequisitionProfilePage,
  PaymentRequisitionCreatePage,
  // WarehouseTasksListPage,
  // FollowServiceTaskListPage,
  VendorProfilePage,
  VendorCreatePage,
  PermissionListPage,
  UserListPage,
  UserProfilePage,
  MyUserProfilePage,
  // TransportTaskListPage,
  TransportTaskTableDataPage,
  TransportTaskProfilePage,
  TransportTaskCreatePage,
  TransportTaskUpdatePage,
  PrepareProductTaskListPage,
  PrepareDocumentTaskListPage,
  MessengerTaskListPage,
  ReturnProductTaskListPage,
  ReturnDocumentTaskListPage,
  ShippingTaskPage,
  QuotationsPDF,
  // QuotationsListPage,
  QuotationsCreationPage,
  QuotationsProfilePage,
  QuotationUpdationPage,
  QuotationTableDataPage,
  TransportShippingDailySummaryPage,
  TransportShippingPaymentDailySummaryPage,
  // SaleOrdersShippingPendingPage,
  SaleOrderDailyPaymentPage,
  TransportPickupTaskCreatePage,
  TransportPickupTaskListPage,
  TransportPickupTaskProfilePage,
  TransportPickupTaskUpdatePage,
  PickupTaskPage,
  PurchaseOrderTableDataPage,
  CustomerRequisitionCreatePage,
  CustomerRequisitionUpdatePage,
  CustomerRequisitionListPage,
  RefundRequisitionsListPage,
  RefundRequisitionProfilePage,
  WarehouseReceiveProductBackTasksListPage,
  RefundRequisitionCreatePage,
  RefundRequisitionUpdatePage,
  CustomerRequisitionProfilePage,
  CustomerRequisitionShippingPendingListPage,
  CustomerCreditorListPage,
  PayoutDailyPaymentPage,
  HomePage,
  MarketingSalesDashboardPage,
  CustomerTableDataPage,
  ProductTableDataPage,
  ProductWithROPTableDataPage,
  // PaymentRequisitionTableDataPage,
  // SaleOrdersPage,
  PWTicketUpdatePage,
  PWTicketCreatePage,
  PWTicketTableDataPage,
  PWTicketProfilePage,
  // SaleOrderTableDataPage,
  TaxInvoiceTableDataPage,
  SOPaymentTransactionTableDataPage,
  ProductReviewDataPage,
  PWTicketPDF,
  SaleOrderTableDataPage,
  WarehousePendingReceiveProductListPage,
  WarehouseReceiveProductActivityTableDataPage,
} from './pages'
import './index.less'
import { PageName, PaymentRequisitionType } from './enums'
import PurchaseOrderProfilePage from './pages/purchase-orders/profile/PurchaseOrderProfilePage'
import { PurchaseOrderUpdatePage } from './pages/purchase-orders/update'
import VendorListPage from './pages/vendors/list-view/VendorListPage'
import { PurchaseOrderPDFPage } from './pages/purchase-orders'
import PaymentRequisitionReceiptPDFPage from './pages/payment-requisitions/id/pdf/PaymentRequisitionReceiptPDFPage'
import PaymentRequisitionPaymentVoucherPDFPage from './pages/payment-requisitions/id/pdf/PaymentRequisitionPaymentVoucherPDFPage'
import PaymentRequisitionCertificateReceiptPDFPage from './pages/payment-requisitions/id/pdf/PaymentRequisitionCertificateReceiptPDFPage'
import PendingPaymentPurchaseOrderListPage from './pages/purchase-orders/list-view/PendingPaymentPurchaseOrderListPage'
import PurchaseOrderPDFEnglishPage from './pages/purchase-orders/id/pdf/PurchaseOrderPDFEnglishPage'
import UserCreatePage from './pages/users/create/UserCreatePage'
import ShippingCoverPDFPage from './pages/transport/pdf/ShippingCoverPDFPage'
import CustomerRequisitionPendingPickupListPage from './pages/customer-requisitions/CustomerRequisitionPendingPickupListPage'
import PickupTasksDailySummary from './pages/transport/summary-daily/PickupTaskDailySummary'
import PickupTaskListPage from './pages/transport/sub-tasks-list/PickupTaskListPage'
// import ProductTransactionListPage from './pages/products/ProductTransactionListPage'

// import PurchaseRequisitionTableDataPage from './pages/purchase-requisitions/list-view/PurchaseRequisitionTableDataPage'

// import PrepareProductTaskTableDataPage from './pages/transport/sub-tasks-list/PrepareProductTaskTableDataPage'
// import WarehousePendingPickupTaskTableDataPage from './pages/warehouse/pending-pickup/WarehousePendingPickupTaskTableDataPage'
// import WarehouseTaskTableDataPage from './pages/warehouse/receive-product-tasks-list/WarehouseTaskTableDataPage'
// import ReturnProductTaskTableDataPage from './pages/transport/sub-tasks-list/ReturnProductTaskTableDataPage'
// import WarehouseReceiveProductBackTaskTableDataPage from './pages/warehouse/receive-product-back-tasks-list/WarehouseReceiveProductBackTaskTableDataPage'

import InvoiceTableDataPage from './pages/documents/invoices/InvoiceTableDataPage'
import ReceiptTableDataPage from './pages/documents/receipts/ReceiptTableDataPage'
import BillingNoteTableDataPage from './pages/documents/billing-notes/BillingNoteTableDataPage'
import CreditNoteTableDataPage from './pages/documents/credit-notes/CreditNoteTableDataPage'
import MarketingSalesGoalDashboardPage from './pages/dashboard/MarketingSalesGoalDashboardPage'
import { SalesGoalAlignmentPage } from './pages/settings'
import ProductAnalyticTableDataPage from './pages/products/ProductAnalyticTableDataPage'
import ProductYearlyReportPage from './pages/dashboard/product/ProductYearlyReportPage'
import ProductMustToPurchaseTableDataPage from './pages/products/ProductMustToPurchaseTableDataPage'
import PurchaseFollowServiceTaskListPage from './pages/purchase/follow-service-tasks-list/PurchaseFollowServiceTaskListPage'

interface ContextProps {
  triggerSnackbar: (message: string) => void
  currentPage: PageName
  setCurrentPage: (page: PageName) => void
}

export const RootContext = createContext<ContextProps>({
  triggerSnackbar: () => {},
  currentPage: PageName.HOME,
  setCurrentPage: (page: PageName) => {},
})

const App: React.FC = (_) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<PageName>(PageName.HOME)

  const renderRoutes = () =>
    checkSession() ? (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            {/* setting */}
            <Route path="/setting/sales-goal-alignment" component={SalesGoalAlignmentPage} />

            {/* refund requisition */}
            <Route
              path="/customers/:id/request-to-refund"
              render={(props) => <RefundRequisitionCreatePage customerId={props.match.params.id} {...props} />}
            />
            {/* customer */}
            <Route
              path="/customers/:id/profile"
              render={(props) => <CustomerProfilePage id={props.match.params.id} {...props} />}
            />
            <Route path="/customers/create" component={CustomerCreationPage} />
            <Route path="/customers/creditors" component={CustomerCreditorListPage} />
            <Route path="/customers" component={CustomerTableDataPage} />
            {/* <Route path="/customers" component={CustomerListPage} /> */}

            {/* sale */}
            <Route path="/quotations/create" component={QuotationsCreationPage} />
            <Route
              path="/quotations/:id/profile"
              render={(props) => <QuotationsProfilePage id={props.match.params.id} />}
            />
            <Route path="/quotations/:id/pdf" component={QuotationsPDF} />
            <Route
              path="/quotations/:id/update"
              render={(props) => <QuotationUpdationPage id={props.match.params.id} />}
            />
            <Route path="/quotations" component={QuotationTableDataPage} />
            {/* <Route path="/quotations" component={QuotationsListPage} /> */}

            <Route path="/sale-orders/create" component={SOCreationPage} />
            <Route path="/sale-orders/:id/pdf" component={SaleOrderPDF} />
            {/* <Route
              path="/sale-orders/:id/update"
              render={(props) => <SaleOrderUpdationPage id={props.match.params.id} />}
            /> */}
            <Route
              path="/sale-orders/:id/create-purchase-requisition"
              render={(props) => <PurchaseRequisitionCreatePage saleOrderId={props.match.params.id} />}
            />
            {/* <Route path="/sale-orders/shipping-pending" component={SaleOrdersShippingPendingPage} /> */}
            <Route path="/sale-orders/:id" render={(props) => <SaleOrderProfilePage id={props.match.params.id} />} />
            <Route path="/sale-orders" component={SaleOrderTableDataPage} />
            {/* <Route path="/sale-orders" component={SaleOrdersPage} /> */}
            <Route path="/invoices/:id/pdf" component={InvoicePDFPage} />
            <Route path="/invoices" component={InvoiceTableDataPage} />
            {/* <Route path="/invoices" component={DocumentInvoicePage} /> */}
            <Route path="/tax-invoices/:id/pdf" component={TaxInvoicePDFPage} />
            <Route path="/tax-invoices" component={TaxInvoiceTableDataPage} />
            {/* <Route path="/tax-invoices" component={DocumentTaxInvoicePage} /> */}
            <Route path="/credit-notes/:id/pdf" component={CreditNotePDFPage} />
            <Route path="/credit-notes" component={CreditNoteTableDataPage} />
            {/* <Route path="/credit-notes" component={DocumentCreditNotePage} /> */}
            <Route path="/receipts/:id/pdf" component={ReceiptPDFPage} />
            <Route path="/receipts" component={ReceiptTableDataPage} />
            {/* <Route path="/receipts" component={ReceiptListPage} /> */}
            <Route path="/billing-notes/:id/pdf" component={BillingNotePDFPage} />
            <Route path="/billing-notes" component={BillingNoteTableDataPage} />
            {/* <Route path="/billing-notes" component={BillingNoteListPage} /> */}

            {/** FINANCE */}
            <Route path="/sale-order-payment-transactions" component={SOPaymentTransactionTableDataPage} />
            <Route path="/sale-order-daily-payment" component={SaleOrderDailyPaymentPage} />
            <Route path="/payout-daily-payment" component={PayoutDailyPaymentPage} />
            <Route path="/sale-orders-payment-deptors" component={SalesDebtorsPage} />
            <Route path="/finance/prepare-document-tasks" component={PrepareDocumentTaskListPage} />
            <Route path="/finance/return-document-tasks" component={ReturnDocumentTaskListPage} />
            <Route
              path="/finance/transport-payment-daily-summary"
              component={TransportShippingPaymentDailySummaryPage}
            />

            <Route path="/finance/refund-requisitions-list" component={RefundRequisitionsListPage} />
            <Route
              path="/finance/refund-requisitions/:id/profile"
              render={(props) => <RefundRequisitionProfilePage refundRequisitionId={props.match.params.id} />}
            />
            <Route
              path="/finance/refund-requisitions/:id/update"
              render={(props) => <RefundRequisitionUpdatePage id={props.match.params.id} {...props} />}
            />

            {/** PRODUCT */}
            <Route
              path="/products/:id/review-data"
              render={(props) => <ProductReviewDataPage id={props.match.params.id} />}
            />
            <Route path="/products/:id/profile" render={(props) => <ProductProfilePage id={props.match.params.id} />} />
            <Route path="/products/create" component={ProductCreationPage} />
            <Route
              path="/products/pending-to-purchase/create-purchase-requisition"
              render={() => <PurchaseRequisitionCreatePage rop={true} />}
            />
            <Route path="/products/analytic" component={ProductAnalyticTableDataPage} />
            <Route path="/products/pending-to-purchase" component={ProductWithROPTableDataPage} />
            <Route path="/products/must-to-purchase" component={ProductMustToPurchaseTableDataPage} />
            {/* <Route path="/products/pending-to-purchase" component={PendingToPurchaseListPage} /> */}
            {/* <Route path="/products/transaction-list" component={ProductTransactionListPage} /> */}
            <Route path="/products" component={ProductTableDataPage} />
            {/* <Route path="/products" component={ProductsPage} /> */}

            {/** PURCHASE */}
            <Route path="/purchase/follow-service-tasks" component={PurchaseFollowServiceTaskListPage} />

            {/** WAREHOUSE */}
            {/* <Route path="/warehouse/prepare-product-tasks" component={PrepareProductTaskTableDataPage} /> */}
            <Route path="/warehouse/prepare-product-tasks" component={PrepareProductTaskListPage} />
            {/* <Route path="/warehouse/return-product-tasks" component={ReturnProductTaskTableDataPage} /> */}
            <Route path="/warehouse/return-product-tasks" component={ReturnProductTaskListPage} />
            {/* <Route path="/warehouse/pending-pickup" component={WarehousePendingPickupTaskTableDataPage} /> */}
            <Route path="/warehouse/pending-pickup" component={WarehousePendingPickupTasksPage} />
            {/* <Route path="/warehouse/receive-product" component={WarehouseTasksListPage} /> */}
            <Route path="/warehouse/receive-product" component={WarehousePendingReceiveProductListPage} />
            <Route
              path="/warehouse/receive-product-activity"
              component={WarehouseReceiveProductActivityTableDataPage}
            />
            {/* <Route
              path="/warehouse/receive-product-back-tasks"
              component={WarehouseReceiveProductBackTaskTableDataPage}
            /> */}
            <Route path="/warehouse/receive-product-back-tasks" component={WarehouseReceiveProductBackTasksListPage} />

            <Route path="/warehouse/product-withdrawal-tickets/:id/pdf" component={PWTicketPDF} />
            <Route path="/warehouse/product-withdrawal-tickets/:id/profile" component={PWTicketProfilePage} />
            <Route path="/warehouse/product-withdrawal-tickets/:id/update" component={PWTicketUpdatePage} />
            <Route path="/warehouse/product-withdrawal-tickets/create" component={PWTicketCreatePage} />
            <Route path="/warehouse/product-withdrawal-tickets" component={PWTicketTableDataPage} />

            {/**  VENDOR */}
            <Route
              path="/vendors/:id/create-payment-requisition"
              render={(props) => <PaymentRequisitionCreatePage vendorId={props.match.params.id} />}
            />
            <Route
              path="/vendors/:id/profile"
              render={(props) => <VendorProfilePage vendorId={props.match.params.id} />}
            />
            <Route path="/vendors/create" component={VendorCreatePage} />
            <Route path="/vendors" component={VendorListPage} />

            {/** PAYMENT REQUISITION */}
            <Route
              path="/purchase-payments/:id/profile"
              render={(props) => (
                <PaymentRequisitionProfilePage
                  paymentRequisitionId={props.match.params.id}
                  typeId={PaymentRequisitionType.Pay}
                />
              )}
            />
            <Route path="/purchase-payments" component={PurchasePaymentListPage} />
            <Route path="/payment-requisitions/:id/pdf/receipt" component={PaymentRequisitionReceiptPDFPage} />
            <Route
              path="/payment-requisitions/:id/pdf/payment-voucher"
              component={PaymentRequisitionPaymentVoucherPDFPage}
            />
            <Route
              path="/payment-requisitions/:id/pdf/certificate-receipt"
              component={PaymentRequisitionCertificateReceiptPDFPage}
            />
            <Route
              path="/payment-requisitions/:id/profile"
              render={(props) => (
                <PaymentRequisitionProfilePage
                  paymentRequisitionId={props.match.params.id}
                  typeId={PaymentRequisitionType.Request}
                />
              )}
            />
            {/* <Route path="/payment-requisitions" component={PaymentRequisitionTableDataPage} /> */}
            <Route path="/payment-requisitions" component={PaymentRequisitionListPage} />

            {/** PURCHASE REQUISITION */}
            <Route
              path="/purchase-requisitions/:id/profile"
              render={(props) => <PurchaseRequisitionProfilePage id={props.match.params.id} />}
            />
            <Route
              path="/purchase-requisitions/:id/update"
              render={(props) => <PurchaseRequisitionUpdatePage id={props.match.params.id} />}
            />
            <Route path="/purchase-requisitions/create" component={PurchaseRequisitionCreatePage} />
            {/* <Route path="/purchase-requisitions" component={PurchaseRequisitionTableDataPage} /> */}
            <Route path="/purchase-requisitions" component={PurchaseRequisitionListPage} />

            {/* PURCHASE ORDER */}
            <Route path="/purchase-orders/:id/pdf/en" component={PurchaseOrderPDFEnglishPage} />
            <Route path="/purchase-orders/:id/pdf" component={PurchaseOrderPDFPage} />
            {/* TODO: rename url */}
            <Route
              path="/purchase-orders/:id/create"
              render={(props) => <PurchaseOrderCreatePage id={props.match.params.id} />}
            />
            <Route
              path="/purchase-orders/:id/update"
              render={(props) => <PurchaseOrderUpdatePage id={props.match.params.id} />}
            />
            <Route
              path="/purchase-orders/:id/profile"
              render={(props) => <PurchaseOrderProfilePage id={props.match.params.id} />}
            />
            <Route path="/purchase-orders/pending-payment" component={PendingPaymentPurchaseOrderListPage} />
            <Route path="/purchase-orders" component={PurchaseOrderTableDataPage} />
            {/* <Route path="/purchase-orders" component={PurchaseOrderListPage} /> */}

            {/* TRANSPORT */}
            <Route path="/transport/shipping-tasks/daily-summary" component={TransportShippingDailySummaryPage} />
            <Route
              path="/transport/shipping-tasks/:id"
              render={(props) => <ShippingTaskPage id={props.match.params.id} />}
            />
            <Route path="/transport/messenger-tasks" component={MessengerTaskListPage} />

            <Route path="/transport-tasks/:id/shipping-cover-pdf" component={ShippingCoverPDFPage} />
            <Route
              path="/transport-tasks/:id/profile"
              render={(props) => <TransportTaskProfilePage id={props.match.params.id} />}
            />
            <Route
              path="/transport-pickup-tasks/:id/profile"
              render={(props) => <TransportPickupTaskProfilePage id={props.match.params.id} />}
            />
            <Route
              path="/transport/pickup-tasks/:id"
              render={(props) => <PickupTaskPage id={props.match.params.id} />}
            />
            <Route
              path="/transport-tasks/:id/update"
              render={(props) => <TransportTaskUpdatePage taskId={props.match.params.id} />}
            />
            <Route
              path="/transport-pickup-tasks/:id/update"
              render={(props) => <TransportPickupTaskUpdatePage taskId={props.match.params.id} />}
            />
            <Route
              path="/transport-tasks/:saleOrderId/create"
              render={(props) => <TransportTaskCreatePage saleOrderId={props.match.params.saleOrderId} />}
            />

            {/* CUSTOMER REQUISITION */}
            <Route
              path="/customer-requisitions/:id/update"
              render={(props) => <CustomerRequisitionUpdatePage id={props.match.params.id} />}
            />
            <Route
              path="/customer-requisitions/:id/profile"
              render={(props) => <CustomerRequisitionProfilePage id={Number(props.match.params.id)} />}
            />
            <Route path="/customer-requisitions/create" component={CustomerRequisitionCreatePage} />
            <Route
              path="/customer-requisitions/shipping-pending"
              component={CustomerRequisitionShippingPendingListPage}
            />
            <Route path="/customer-requisitions/pending-pickup" component={CustomerRequisitionPendingPickupListPage} />
            <Route path="/customer-requisitions/create" component={CustomerRequisitionCreatePage} />
            <Route path="/customer-requisitions" component={CustomerRequisitionListPage} />

            {/* temp path */}
            <Route path="/transport-tasks/create-pickup-task" component={TransportPickupTaskCreatePage} />
            <Route path="/transport-tasks" component={TransportTaskTableDataPage} />
            {/* <Route path="/transport-tasks" component={TransportTaskListPage} /> */}

            {/* temp path related transport pickup tasks */}
            <Route path="/transport-pickup-tasks/messenger-tasks" component={PickupTaskListPage} />
            <Route path="/transport-pickup-tasks/pickup-tasks/daily-summary" component={PickupTasksDailySummary} />
            <Route path="/transport-pickup-tasks" component={TransportPickupTaskListPage} />

            <Route path="/users/:id/profile" render={(props) => <UserProfilePage id={props.match.params.id} />} />
            <Route path="/users/my-profile" component={MyUserProfilePage} />
            <Route path="/users/create" component={UserCreatePage} />
            <Route path="/users/permissions" component={PermissionListPage} />
            <Route path="/users" component={UserListPage} />

            <Route path="/dashboard/product-yearly-report" component={ProductYearlyReportPage} />
            <Route path="/dashboard/sales-goal" component={MarketingSalesGoalDashboardPage} />
            <Route path="/dashboard/sales" component={MarketingSalesDashboardPage} />
            <Route exact path="/" component={HomePage} />
            <Route path="/" component={NotFoundPage} />
          </Switch>
        </Router>
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <LoginPage />
      </ThemeProvider>
    )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
      <RootContext.Provider
        value={{
          triggerSnackbar: (message: string) => setSnackbarMessage(message),
          currentPage,
          setCurrentPage,
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!snackbarMessage}
          autoHideDuration={3000}
          onClose={(event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
            setSnackbarMessage(undefined)
          }}
          message={snackbarMessage}
        />
        {renderRoutes()}
      </RootContext.Provider>
    </MuiPickersUtilsProvider>
  )
}

ReactDOM.render(<App />, document.querySelector('#root'))
