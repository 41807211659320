import {
  Home as HomeIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  StoreMallDirectory as StoreMallDirectoryIcon,
  LocalShipping as LocalShippingIcon,
  AllInbox as AllInboxIcon,
  AttachMoney as AttachMoneyIcon,
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  EmojiPeople as EmojiPeopleIcon,
  ShowChart,
  // NewReleases,
} from '@material-ui/icons'
import { PageName, NavigationItemKey } from '../../enums'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { ReactComponent as AfterServiceIconSVG } from '../../assets/icons/after-service-icon.svg'
import { getUserPermissionInfo } from '../../utils/permission'

interface NavigationItem {
  key: NavigationItemKey
  pageName?: PageName
  name: string
  path?: string
  children?: NavigationItem[]
  icon?: JSX.Element
  suffixIcon?: JSX.Element
  href?: string
  render: boolean
}

const saleNavigationItems: NavigationItem[] = [
  {
    key: NavigationItemKey.Quotation,
    pageName: PageName.QUOTATION,
    name: 'รายการใบเสนอราคา',
    path: '/quotations',
    render: getUserPermissionInfo(PermissionEnum.Sales_Quotation_Read).hasPermission,
  },
  {
    key: NavigationItemKey.SaleOrder,
    pageName: PageName.SALE_ORDER,
    name: 'รายการใบสั่งขาย',
    path: '/sale-orders',
    render: getUserPermissionInfo(PermissionEnum.Sales_SO_Read).hasPermission,
  },
  // {
  //   key: NavigationItemKey.ReadyToShipSaleOrder,
  //   pageName: PageName.SHIPPING_PENDING,
  //   name: 'รายการพร้อมส่ง',
  //   path: '/sale-orders/shipping-pending',
  //   render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Write).hasPermission,
  // },
  {
    key: NavigationItemKey.Invoice,
    pageName: PageName.INVOICE,
    name: 'รายการใบแจ้งหนี้',
    path: '/invoices',
    render: getUserPermissionInfo(PermissionEnum.Finance_Document_Read).hasPermission,
  },
  {
    key: NavigationItemKey.TaxInvoice,
    pageName: PageName.TAX_INVOICE,
    name: 'รายการใบกำกับภาษี',
    path: '/tax-invoices',
    render: getUserPermissionInfo(PermissionEnum.Finance_Document_Read).hasPermission,
  },
  {
    key: NavigationItemKey.Receipt,
    pageName: PageName.RECEIPT,
    name: 'รายการใบเสร็จรับเงิน',
    path: '/receipts',
    render: getUserPermissionInfo(PermissionEnum.Finance_Document_Read).hasPermission,
  },
  {
    key: NavigationItemKey.BillingNote,
    pageName: PageName.BILLING_NOTE,
    name: 'รายการใบวางบิล',
    path: '/billing-notes',
    render: getUserPermissionInfo(PermissionEnum.Finance_Document_Read).hasPermission,
  },
  {
    key: NavigationItemKey.CreditNote,
    pageName: PageName.CREDIT_NOTE,
    name: 'รายการใบลดหนี้',
    path: '/credit-notes',
    render: getUserPermissionInfo(PermissionEnum.Finance_Document_Read).hasPermission,
  },
]

const financeItems: NavigationItem[] = [
  {
    key: NavigationItemKey.SalesPayment,
    pageName: PageName.SO_PAYMENT,
    name: 'รายการรายได้',
    path: '/sale-order-payment-transactions',
    render: getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Read).hasPermission,
  },
  {
    key: NavigationItemKey.SaleOrderDailyPayment,
    pageName: PageName.SALE_ORDER_DAILY_PAYMENT,
    name: 'รายงานกระแสเงินสดรับรายวัน',
    path: '/sale-order-daily-payment',
    render: getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PayOutDailyPayment,
    pageName: PageName.FINANCE_PAYOUT_DAILY_PAYMENT,
    name: 'รายการกระแสเงินสดจ่ายรายวัน',
    path: '/payout-daily-payment',
    render: getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PaymentRequisition,
    pageName: PageName.PAYMENT_REQUISITION,
    name: 'รายการทำเบิก',
    path: '/payment-requisitions',
    render: getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PurchasePayment,
    pageName: PageName.PURCHASE_PAYMENT,
    name: 'รายการทำจ่าย',
    path: '/purchase-payments',
    render: getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Read).hasPermission,
  },
  {
    key: NavigationItemKey.SalesDebtors,
    pageName: PageName.SO_DEBTOR,
    name: 'รายการลูกหนี้',
    path: '/sale-orders-payment-deptors',
    render: getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Write).hasPermission,
  },
  {
    key: NavigationItemKey.PrepareDocument,
    pageName: PageName.FINANCE_PREPARE_DOCUMENT_TASK,
    name: 'งานเตรียมเอกสาร',
    path: '/finance/prepare-document-tasks',
    render: getUserPermissionInfo(PermissionEnum.Finance_Task_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ReturnDocument,
    pageName: PageName.FINANCE_RETURN_DOCUMENT_TASK,
    name: 'งานคืนเอกสาร',
    path: '/finance/return-document-tasks',
    render: getUserPermissionInfo(PermissionEnum.Finance_Task_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ShippingPaymentSummaryData,
    pageName: PageName.FINANCE_TRANSPORT_PAYMENT_DAILY_SUMMARY,
    name: 'การเก็บเงินงานขนส่งรายวัน',
    path: '/finance/transport-payment-daily-summary',
    render: getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Write).hasPermission,
  },
  {
    key: NavigationItemKey.RefundServiceList,
    pageName: PageName.FINANCE_REFUND_REQUISITION_LIST,
    name: 'รายการคืนเงิน',
    path: '/finance/refund-requisitions-list',
    render: getUserPermissionInfo(PermissionEnum.FinancePay_RefundRequisition_Read).hasPermission,
  },
]

const warehouseItems: NavigationItem[] = [
  // {
  //   key: NavigationItemKey.Product,
  //   pageName: PageName.PRODUCT,
  //   name: 'รายการสินค้า',
  //   path: '/products',
  //   render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  // },
  {
    key: NavigationItemKey.Product,
    pageName: PageName.PRODUCT,
    name: 'รายการสินค้าและบริการ',
    path: '/products',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  },
  {
    key: NavigationItemKey.Transactions,
    pageName: PageName.PRODUCT_TRANSACTION,
    name: 'รายงานสินค้าเคลื่อนไหว',
    // path: '/products/transaction-list',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Report).hasPermission,
    href: `${process.env.REACT_APP_BACKOFFICE_V2}/warehouse/product-stock-activity`,
  },
  {
    key: NavigationItemKey.ProductAnalytic,
    pageName: PageName.PRODUCT_ANALYTIC,
    name: 'วิเคราะห์รายการสินค้า',
    path: '/products/analytic',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Report).hasPermission,
  },
  {
    key: NavigationItemKey.ReorderPoint,
    pageName: PageName.PRODUCT_REORDER_POINT,
    name: 'รายการสินค้าที่ควรซื้อเพิ่ม',
    path: '/products/pending-to-purchase',
    render: getUserPermissionInfo(PermissionEnum.Purchase_PR_Write).hasPermission,
  },
  {
    key: NavigationItemKey.ProductMustToPurchase,
    pageName: PageName.PRODUCT_MUST_TO_PURCHASE,
    name: 'รายการสินค้าที่ต้องสั่งซื้อ',
    path: '/products/must-to-purchase',
    render: getUserPermissionInfo(PermissionEnum.Purchase_PR_Write).hasPermission,
  },
  {
    key: NavigationItemKey.PrepareProductToShip,
    pageName: PageName.WAREHOUSE_PREPARE_TO_SHIP,
    name: 'งานเตรียมสินค้าสำหรับส่ง',
    path: '/warehouse/prepare-product-tasks',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  },
  {
    key: NavigationItemKey.CustomerPickupProduct,
    pageName: PageName.WAREHOUSE_PEDNING_CUSTOMER_PICKUP,
    name: 'รายการนัดรับหน้าร้าน',
    path: '/warehouse/pending-pickup',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PendingVendorShipping,
    pageName: PageName.WAREHOUSE_PENDING_VENDOR_SHIPPING,
    name: 'ติดตามสินค้าและบริการจากผู้ขาย',
    path: '/warehouse/receive-product',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ReturnProduct,
    pageName: PageName.WAREHOUSE_RETURN_PRODUCT_TASK,
    name: 'รายการงานรับคืนสต็อก',
    path: '/warehouse/return-product-tasks',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Product_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ReceiveProductBack,
    pageName: PageName.WAREHOUSE_RECEIVE_PRODUCT_BACK_TASK,
    name: 'งานรับสินค้าเคลม/คืน',
    path: '/warehouse/receive-product-back-tasks',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_Task_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ProductWithdrawalTableData,
    pageName: PageName.PRODUCT_WITHDRAWAL_TABLE_DATA,
    name: 'รายการคำขอเบิกสินค้า',
    path: '/warehouse/product-withdrawal-tickets',
    render: getUserPermissionInfo(PermissionEnum.Warehouse_WithdrawalTicket_Read).hasPermission,
  },
]

const purchaseItems: NavigationItem[] = [
  {
    key: NavigationItemKey.PurchaseRequisition,
    pageName: PageName.PURCHASE_REQUISITION,
    name: 'รายการคำขอซื้อ',
    path: '/purchase-requisitions',
    render: getUserPermissionInfo(PermissionEnum.Purchase_PR_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PurchaseOrder,
    pageName: PageName.PURCHASE_ORDER,
    name: 'รายการใบสั่งซื้อ',
    path: '/purchase-orders',
    render: getUserPermissionInfo(PermissionEnum.Purchase_PO_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PendingPaymentPurchaseOrder,
    pageName: PageName.PENDING_PAYMENT_PURCHASE_ORDER,
    name: 'รายการที่ต้องทำเบิก',
    path: '/purchase-orders/pending-payment',
    render: getUserPermissionInfo(PermissionEnum.FinancePay_PaymentRequisition_Write).hasPermission,
  },
  // {
  //   key: NavigationItemKey.FollowServiceTask,
  //   pageName: PageName.PURCHASE_FOLLOW_SERVICE_TASK,
  //   name: 'รายงานติดตามบริการ',
  //   path: '/purchase/follow-service-tasks',
  //   render: getUserPermissionInfo(PermissionEnum.Warehouse_Task_Read).hasPermission,
  // },
]

const transportItems: NavigationItem[] = [
  {
    key: NavigationItemKey.TransportTask,
    pageName: PageName.TRANSPORT,
    name: 'รายการบริการงานขนส่ง',
    path: '/transport-tasks',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
  {
    key: NavigationItemKey.TransportShippingSummary,
    pageName: PageName.MESSENGER_TASK_DAILY_SUMMARY,
    name: 'การขนส่งรายวัน',
    path: '/transport/shipping-tasks/daily-summary',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
  {
    key: NavigationItemKey.ShippingTask,
    pageName: PageName.MESSENGER_TASK,
    name: 'รายการงานส่งของ',
    path: '/transport/messenger-tasks',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
]

const transportPickupItems: NavigationItem[] = [
  {
    key: NavigationItemKey.TransportPickupTask,
    pageName: PageName.TRANSPORT_PICKUP_TASK,
    name: 'รายการบริการงานรับของ',
    path: '/transport-pickup-tasks',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
  {
    key: NavigationItemKey.TransportPickupSummary,
    pageName: PageName.MESSENGER_PICKUP_TASK_DAILY_SUMMARY,
    name: 'การรับของรายวัน',
    path: '/transport-pickup-tasks/pickup-tasks/daily-summary',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
  {
    key: NavigationItemKey.PickupTask,
    pageName: PageName.MESSENGER_PICKUP_TASK,
    name: 'รายการงานรับของ',
    path: '/transport-pickup-tasks/messenger-tasks',
    render: getUserPermissionInfo(PermissionEnum.Service_TransportTask_Read).hasPermission,
  },
]

const vendorItems: NavigationItem[] = [
  {
    key: NavigationItemKey.VendorList,
    pageName: PageName.VENDOR,
    name: 'รายการผู้ขาย',
    path: '/vendors',
    render: getUserPermissionInfo(PermissionEnum.Purchase_Vendor_Read).hasPermission,
  },
]

const userItems: NavigationItem[] = [
  {
    key: NavigationItemKey.UserList,
    pageName: PageName.USER,
    name: 'รายการผู้ใช้ในระบบ',
    path: '/users',
    render: getUserPermissionInfo(PermissionEnum.HR_User_Read).hasPermission,
  },
  {
    key: NavigationItemKey.UserPermissionList,
    pageName: PageName.PERMISSION,
    name: 'การตั้งค่าสิทธิ์การใช้งาน',
    path: '/users/permissions',
    render: getUserPermissionInfo(PermissionEnum.HR_User_Read).hasPermission,
  },
]

const customerItems: NavigationItem[] = [
  // {
  //   key: NavigationItemKey.CustomerList,
  //   pageName: PageName.CUSTOMER,
  //   name: 'รายการลูกค้า',
  //   path: '/customers',
  //   render: getUserPermissionInfo(PermissionEnum.Sales_Customer_Read).hasPermission,
  // },
  {
    key: NavigationItemKey.CustomerList,
    pageName: PageName.CUSTOMER,
    name: 'รายการลูกค้า',
    path: '/customers',
    render: getUserPermissionInfo(PermissionEnum.Sales_Customer_Read).hasPermission,
  },
  {
    key: NavigationItemKey.CreditorList,
    pageName: PageName.CREDITOR,
    name: 'รายการเจ้าหนี้',
    path: '/customers/creditors',
    render: getUserPermissionInfo(PermissionEnum.Sales_Customer_Read).hasPermission,
  },
]

const customerServiceNavigationItems: NavigationItem[] = [
  {
    key: NavigationItemKey.CustomerRequisition,
    pageName: PageName.CUSTOMER_REQUISITION_LIST,
    name: 'รายการคำร้องของลูกค้า',
    path: '/customer-requisitions',
    render: getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Read).hasPermission,
  },
  {
    key: NavigationItemKey.CustomerRequisitionPendingPickup,
    pageName: PageName.CUSTOMER_REQUISITION_PENDING_PICKUP,
    name: 'คำร้องที่รอไปรับจากลูกค้า',
    path: '/customer-requisitions/pending-pickup',
    render: getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Read).hasPermission,
  },
  {
    key: NavigationItemKey.CustomerRequisition,
    pageName: PageName.CUSTOMER_REQUISITION_SHIPPING_PENDING,
    name: 'รายการพร้อมส่งคืนลูกค้า',
    path: '/customer-requisitions/shipping-pending',
    render: getUserPermissionInfo(PermissionEnum.Service_CustomerRequisition_Read).hasPermission,
  },
]

const marketingReportItems: NavigationItem[] = [
  {
    key: NavigationItemKey.MarketingSalesReport,
    pageName: PageName.MARKETING_SALES_REPORT,
    name: 'รายงานการขาย',
    path: '/dashboard/sales',
    render: getUserPermissionInfo(PermissionEnum.Marketing_Sales_Report).hasPermission,
  },
  {
    key: NavigationItemKey.MarketingSalesGoalReport,
    pageName: PageName.MARKETING_SALES_GOAL_REPORT,
    name: 'รายงานเป้าการขาย',
    path: '/dashboard/sales-goal',
    render: getUserPermissionInfo(PermissionEnum.Sales_GoalAligntment_Read).hasPermission,
  },
  {
    key: NavigationItemKey.MarketingSalesGoalReport,
    pageName: PageName.MARKETING_PRODUCT_YEARLY_REPORT,
    name: 'รายงานสินค้ารายปี',
    path: '/dashboard/product-yearly-report',
    render: getUserPermissionInfo(PermissionEnum.Marketing_Sales_Report).hasPermission,
  },
  {
    key: NavigationItemKey.MarketingSalesGoalReport,
    pageName: PageName.MARKETING_PRODUCT_DASHBOARD_REPORT,
    name: 'รายงานการขายสินค้า',
    href: `${process.env.REACT_APP_BACKOFFICE_V2}`,
    render: getUserPermissionInfo(PermissionEnum.Marketing_Sales_Report).hasPermission,
  },
]

const settingItems: NavigationItem[] = [
  {
    key: NavigationItemKey.SalesGoalAlignment,
    pageName: PageName.SALES_GOAL_ALIGNMENT,
    name: 'เป้าการขาย',
    path: '/setting/sales-goal-alignment',
    render: getUserPermissionInfo(PermissionEnum.Sales_GoalAligntment_Read).hasPermission,
  },
]

const navigationItems: NavigationItem[] = [
  {
    key: NavigationItemKey.Home,
    pageName: PageName.HOME,
    name: 'หน้าแรก',
    path: '/',
    icon: <HomeIcon />,
    render: true,
  },
  {
    key: NavigationItemKey.Marketing,
    pageName: PageName.MARKETING,
    name: 'Dashboard',
    children: marketingReportItems,
    icon: <ShowChart />,
    render: marketingReportItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Customer,
    name: 'ลูกค้า',
    children: customerItems,
    icon: <SupervisedUserCircleIcon />,
    render: customerItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Vendor,
    name: 'ผู้ขาย',
    children: vendorItems,
    icon: <EmojiPeopleIcon />,
    render: vendorItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Sale,
    name: 'การขาย',
    children: saleNavigationItems,
    icon: <StoreMallDirectoryIcon />,
    render: saleNavigationItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.CustomerService,
    name: 'บริการหลังการขาย',
    children: customerServiceNavigationItems,
    icon: <AfterServiceIconSVG />,
    render: customerServiceNavigationItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Transport,
    pageName: PageName.TRANSPORT,
    name: 'บริการขนส่ง',
    children: transportItems,
    icon: <LocalShippingIcon />,
    render: transportItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.TransportPickup,
    pageName: PageName.TRANSPORT_PICKUP,
    name: 'บริการรับของ',
    children: transportPickupItems,
    icon: <LocalShippingIcon />,
    render: transportPickupItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Purchase,
    pageName: PageName.PURCHASE_REQUISITION,
    name: 'การสั่งซื้อ',
    children: purchaseItems,
    icon: <ShoppingCartIcon />,
    render: purchaseItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Warehouse,
    pageName: PageName.WAREHOUSE_PREPARE_TO_SHIP,
    name: 'คลังสินค้า',
    children: warehouseItems,
    icon: <AllInboxIcon />,
    render: warehouseItems.some((item) => item.render),
  },

  {
    key: NavigationItemKey.Finance,
    name: 'การเงิน',
    children: financeItems,
    icon: <AttachMoneyIcon />,
    render: financeItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.User,
    pageName: PageName.USER,
    name: 'ผู้ใช้',
    children: userItems,
    icon: <PersonIcon />,
    render: userItems.some((item) => item.render),
  },
  {
    key: NavigationItemKey.Setting,
    pageName: PageName.SETTING,
    name: 'ตั้งค่า',
    children: settingItems,
    icon: <PersonIcon />,
    render: settingItems.some((item) => item.render),
  },
]

export default navigationItems
